import React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "@foris/avocado-ui";
import { BreadCrumb } from "../../../../common/components";
import css from "./layout.module.scss";

const breadCrumbItems = [{ content: "Editor de demanda", link: false }];

interface ILayoutProps {
  children: React.ReactNode;
  context: any;
}

const Layout = (props: ILayoutProps) => {
  const { children, context } = props;
  const history = useHistory();
  const contextOld = context.routes.context;

  const userPermissions = context.auth.role.permissions;
  const isAllTheContext =
    contextOld && contextOld.project && contextOld.scenario && contextOld.workspace !== null;
  const canCreateCrosslist =
    isAllTheContext &&
    userPermissions &&
    userPermissions.crosslist.includes("create") &&
    userPermissions.crosslistManagement;

  return (
    <main className={cx(css.layoutContainer, "container-row")}>
      <div className="col">
        {isAllTheContext && <BreadCrumb items={breadCrumbItems} />}
        {userPermissions && canCreateCrosslist && (
          <Button
            typeButton="transparent"
            className={cx(css.link__button)}
            onClick={() => history.push(props.context.routes.crosslistAdd())}
          >
            Crear lista cruzada
          </Button>
        )}
        {children}
      </div>
    </main>
  );
};

export default Layout;
