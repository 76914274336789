import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import useEditDemand from "../graphql/useEditDemand";
import EDIT_CONFIRM_CROSSLIST from "../graphql/editConfirmCrosslist";
import cx from "classnames";
import { IForecastContextInterface } from "../../../../../context";
import Table, { CheckboxCell, InputCell } from "../../../../../../../common/components/Table";
import HeaderDemand from "../HeaderDemand/HeaderDemand";
import Paginator from "../../../../../../../common/components/Paginator/Paginator";
import Icon from "../../../../../../../common/components/Icon/Icon";
import Checkbox from "../../../../../../../common/components/Checkbox/Checkbox";
import { DemandAdjustment } from "../../../../../../../models/ISchema";
import { useStringFilterParams } from "../../../../../../dashboard/utils/utils";
import fakeAuth from "../../../../../../../authClient";
import LoaderTable from "../../../../../../../common/components/LoaderTable/LoaderTable";
import SearchInput from "../../../../../../../common/components/SearchInput/SearchInput";
import css from "./ruleCode.module.scss";

interface IRuleCodeProps {
  project: any;
  isLoading: boolean;
  searchTerm: string;
  forecastContext: IForecastContextInterface;
  setDemandFilter: (page: number, searchTerm: string) => void;
}

interface IPageInfo {
  total: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  page: number;
  size: number;
}

const RuleCode: React.FC<IRuleCodeProps> = (props: IRuleCodeProps) => {
  const { project, setDemandFilter, isLoading, searchTerm } = props;
  const context: IForecastContextInterface = props.forecastContext;
  const [data, setData] = useState(project.unit.demandsPage.items);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingPaginator, setIsLoadingPaginator] = useState(false);
  const [confirmCrosslist, setConfirmCrosslist] = useState<boolean>(
    project.unit.crosslistConfirmation,
  );
  const demandDistribution = project.unit.demandDistribution;
  const [disabledCheckConfirmCrosslist, setDisabledCheckConfirmCrosslist] = useState(true);
  const pageInfo: IPageInfo = project.unit.demandsPage.pageInfo;
  const editDemand = useEditDemand();
  const [confirmUnit] = useMutation(EDIT_CONFIRM_CROSSLIST);
  const [totalAdjusted, setTotalAdjusted] = useState(project.unit.totalAdjusted);
  const routeBack = context.routes.dashboard();
  const stringFilter = useStringFilterParams();
  const [loading, setLoading] = useState(false);
  const [lastRowUpdatedId, setLastRowUpdatedId] = useState<number>(null);
  let countDemandOwn = 0;
  const selectedRow = data.map((value: any) => {
    if (value.own) countDemandOwn++;
    return value.checked;
  });
  const userHasEditPermission =
    fakeAuth.getUserData().role.permissions?.crosslist?.some((item: any) => item === "update") ||
    false;

  const totalProjected = project.unit.totalProjected;

  useEffect(() => {
    setData(project.unit.demandsPage.items);
  }, [project]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingPaginator(false);
      setIsLoadingFilter(false);
    }
  }, [isLoading]);

  const handleSaveDemand = async () => {
    try {
      setLoading(true);
      const unitId = project.unit.id;
      const demandAdjustments: DemandAdjustment[] = data.map((demand: any) => {
        return {
          demandId: demand.id,
          adjusted: demand.adjusted,
          confirmed: demand.checked,
        };
      });
      const variables = {
        unitId,
        demandAdjustments,
      };
      if (demandAdjustments.length > 0) {
        const response = await editDemand.editDemand(variables, context);
        setTotalAdjusted(response.data.data.editDemand.totalAdjusted);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleSaveDemand();
  }, [data]);

  const handleSaveConfirmCrosslist = async newCheckValue => {
    try {
      setLoading(true);
      const variables = {
        unitId: project.unit.id,
        confirmed: newCheckValue,
      };
      const response = await confirmUnit({ variables });
      if (response.data.confirmUnit.status.code === 200) {
        setConfirmCrosslist(newCheckValue);
        setLoading(false);
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const courseCell = (data: any) => {
    const demand: any = data.row.original;
    const courseId = demand?.relatedUnits?.subject?.id;
    const courseRoute = courseId ?? context?.routes.unit(courseId);
    return courseId ? (
      <a className={css.cellLink} href={`${courseRoute}${stringFilter}`}>
        {data?.value?.code}
        <span className={css.cellLink_bold}>{` ${data?.value?.name}`}</span>
      </a>
    ) : (
      <p className={css.cellNormal}>
        {data?.value?.code || ""}
        <span className={css.cellNormal_bold}>{` ${data?.value?.name}` || ""}</span>
      </p>
    );
  };

  const adjustedCell = (data: any) => {
    const demand: any = data.row.original;
    if (!demand?.own) return `${data.value}`;
    return (
      <InputCell
        disabled={data.state.selectedRowIds[data.row.index] || confirmCrosslist || loading}
        value={data.value}
        onChange={(value: string) => {
          const inputValue = value ? parseInt(value) : 0;
          data.updateImplementedData(setData, data.row.index, "adjusted", inputValue);
          setLastRowUpdatedId(data.row.id);
        }}
      />
    );
  };

  const headerCheckedCell = (data: any) => {
    const handleChange = () => {
      const { toggleAllRowsSelected, updateImplementAllData, column, isAllRowsSelected } = data;
      const newChecked = !isAllRowsSelected;
      toggleAllRowsSelected(true);
      updateImplementAllData(setData, column.id, newChecked);
      setLastRowUpdatedId(null);
    };
    return (
      <div className={cx("container-row", "row_align--center")}>
        <CheckboxCell
          {...data.getToggleAllRowsSelectedProps({ onChange: handleChange })}
          disabled={countDemandOwn === 0 || confirmCrosslist || loading}
        />
        <label className={css.labelCheckbox}>Confirmada</label>
      </div>
    );
  };

  const checkedCell = (data: any) => {
    const demand: any = data.row.original;
    const disabled = !demand?.own;
    const handleChange = () => {
      const value = !data.row.isSelected;
      data.toggleRowSelected(data.row.id, value);
      data.updateImplementedData(setData, data.row.index, data.column.id, value);
      setLastRowUpdatedId(data.row.id);
    };
    return (
      <div className={cx("container-row", "row_align--center")}>
        <CheckboxCell
          {...data.row.getToggleRowSelectedProps({ onChange: handleChange })}
          disabled={disabled || loading || confirmCrosslist}
        />
        {loading && data.row.id === lastRowUpdatedId && (
          <label className={css.labelCheckbox}>Cargando ...</label>
        )}
        {!loading && data.row.id === lastRowUpdatedId && (
          <label className={cx(css.labelCheckbox, css.labelCheckbox_confirm)}>
            <Icon icon="check" className={cx(css.labelCheckbox_confirm_icon)} />
            Guardado
          </label>
        )}
      </div>
    );
  };

  const crosslistCell = (data: any) => {
    const demand: any = data.row.original;
    const crossListId = demand?.relatedUnits?.crosslist?.id;
    const crossListRoute = crossListId ?? context?.routes.unit(crossListId);
    return crossListId ? (
      <a className={css.cellLink} href={`${crossListRoute}${stringFilter}`}>
        {data?.value}
      </a>
    ) : (
      <p className={css.cellNormal}>{data?.value || ""}</p>
    );
  };

  const columns: any = [
    {
      Header: "Sede",
      accessor: "course.curriculum.program.campus.code",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Asignatura",
      accessor: "course",
      minWidth: 400,
      Cell: (data: any) => courseCell(data),
    },
    {
      Header: "Lista cruzada",
      accessor: "relatedUnits.crosslist.code",
      minWidth: 150,
      maxWidth: 250,
      Cell: (data: any) => crosslistCell(data),
    },
    {
      Header: "Proyectada",
      accessor: "value",
      maxWidth: 175,
    },
    {
      Header: "Ajustada",
      accessor: "adjusted",
      maxWidth: 175,
      Cell: (data: any) => adjustedCell(data),
    },
    {
      Header: (data: any) => headerCheckedCell(data),
      accessor: "checked",
      minWidth: 140,
      maxWidth: 175,
      Cell: (data: any) => checkedCell(data),
    },
  ];

  return (
    <section className={cx(css.cntRuleCode, "container-row")}>
      <a
        className={cx(css.back, "container-row", "row_align--center")}
        href={`${routeBack}${stringFilter}`}
      >
        <Icon icon="arrow-left" className={cx(css.back_icon)} />
        volver al inicio
      </a>
      <header className={cx(css.cntRuleCode_header, "container-row", "col_12")}>
        <h2 className={css.title}>
          {`${project.unit.label} | `}
          <span className={cx(css.title_detail, css.title_detail__lower)}>Código Regla: </span>
          <span className={css.title_detail}>{` ${project.unit.label}`}</span>
        </h2>
        {userHasEditPermission && demandDistribution.length > 0 && (
          <div className={css.crosslistConfirmation}>
            <Checkbox
              label={"Aprobada"}
              check={confirmCrosslist}
              disabled={disabledCheckConfirmCrosslist}
              onChange={handleSaveConfirmCrosslist}
            />
          </div>
        )}
        {!userHasEditPermission && demandDistribution.length > 0 && (
          <p className={css.crosslistConfirmation}>
            <Icon icon="check" className={cx(css.check_icon)} />
            Aprobada
          </p>
        )}
      </header>

      {
        <div className={cx(css.headerDemand, "col_12")}>
          <HeaderDemand
            onChangeDemands={apertureDifference =>
              setDisabledCheckConfirmCrosslist(apertureDifference !== 0)
            }
            disabled={confirmCrosslist}
            projectId={project.unit.id}
            totalAdjusted={totalAdjusted}
            totalProjected={totalProjected}
            demandDistribution={demandDistribution}
          />
        </div>
      }

      <div className="container-row row_aling--center">
        <div className={cx("col_2", css.cntSearchInput)}>
          <SearchInput
            placeholder="Filtrar"
            value={searchTerm}
            onClick={inputValue => {
              setIsLoadingFilter(true);
              setDemandFilter(1, inputValue);
            }}
          />
        </div>
        {isLoadingFilter && (
          <div className={cx("col_1", css.cntSearchInputLoader)}>
            <LoaderTable />
          </div>
        )}
      </div>

      <Table columns={columns} data={data} initSelectedRowIds={selectedRow} />

      <div className={cx(css.cntRuleCode_save, "container-row", "row--center")}>
        <Paginator
          total={pageInfo.total}
          pageSize={pageInfo.size}
          current={pageInfo.page}
          onChange={currentPage => {
            setIsLoadingPaginator(true);
            setDemandFilter(currentPage, "");
          }}
        />
        {isLoadingPaginator && (
          <div className={cx(css.cntPaginatorLoader)}>
            <LoaderTable />
          </div>
        )}
      </div>
    </section>
  );
};

export default RuleCode;
