const filterByTextInsideObjects = (object: any, searchKey: string): object => {
  const newObj: any = {};
  Object.keys(object).forEach((key: any): any => {
    if (
      object[key].name.toLowerCase().search(searchKey) !== -1 ||
      object[key].code.toLowerCase().search(searchKey) !== -1
    ) {
      newObj[key] = object[key];
    }
  });
  return newObj;
};

const isNumber = (value: string | number): boolean => {
  return value != null && !isNaN(Number(value.toString()));
};

export { filterByTextInsideObjects, isNumber };
