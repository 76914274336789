import { useLocation } from "react-router-dom";

/**
 * hook useLocation - get optionals params
 */
export const useQueryParam = () => new URLSearchParams(useLocation().search);

const generateStringParam = (
  campus: string,
  department: string,
  program: string,
  curriculum: string,
  modality: string,
  shift: string,
  tab = "0",
) => {
  const params = [];
  campus && params.push(`campus=${campus}`);
  department && params.push(`department=${department}`);
  program && params.push(`program=${program}`);
  curriculum && params.push(`curriculum=${curriculum}`);
  modality && params.push(`modality=${modality}`);
  shift && params.push(`shift=${shift}`);
  params.push(`tab=${tab}`);
  let stringFilter = "";
  if (params.length > 0) {
    params.forEach(value => {
      stringFilter = stringFilter ? `${stringFilter}&${value}` : `${value}`;
    });
  }
  return `?${stringFilter}`;
};

/**
 * Return string with params filter
 */
export const useStringFilterParams = () => {
  const campus = useQueryParam()?.get("campus");
  const department = useQueryParam()?.get("department");
  const program = useQueryParam()?.get("program");
  const curriculum = useQueryParam()?.get("curriculum");
  const tab = useQueryParam()?.get("tab");
  const modality = useQueryParam()?.get("modality");
  const shift = useQueryParam()?.get("shift");
  return generateStringParam(campus, department, program, curriculum, modality, shift, tab);
};

/**
 * Return string with filter or params selected
 */
export const useGenerateStringFilter = (
  campus: string,
  department: string,
  program: string,
  curriculum: string,
  modality: string,
  shift: string,
  tab = "0",
) => {
  return generateStringParam(campus, department, program, curriculum, modality, shift, tab);
};

export const pipe = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
  fns.reduce((prevFn, nextFn) => value => nextFn(prevFn(value)), fn1);
