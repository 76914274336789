import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import SubjectListItem from "./SubjectListItem";
import {
  ISubject,
  IFilterSelected,
} from "../../../../forecast/modules/demand-editor/pages/dashboard/models";
import Forecast from "../../../../../routes/Forecast";
import Input from "../../../../../common/components/Input/Input";
import css from "./subjectListView.module.scss";
import { debounce } from "lodash";

interface ISubjectListViewProps {
  items: Array<ISubject>;
  routes?: Forecast;
  filterSelected?: IFilterSelected;
}

const SubjectListView: React.FC<ISubjectListViewProps> = (props: ISubjectListViewProps) => {
  const { items, routes, filterSelected } = props;
  const [source, setSource] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const filterList = (value: string | number) => {
    if (value === "") setSource(items);
    const valueSearch = value
      .toString()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const updatedList: any = [];

    items.forEach((item: ISubject): any => {
      const label = item.unit.label
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const subject = item.subject
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const isContainAcceSsCode = item.unit.access.some(access => {
        const accessFormat = access
          .toString()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return accessFormat.search(valueSearch) !== -1;
      });
      if (
        label.search(valueSearch) !== -1 ||
        subject.search(valueSearch) !== -1 ||
        isContainAcceSsCode
      ) {
        updatedList.push(item);
      }
    });
    setSource(updatedList);
  };

  const delayedFilterList = useCallback(debounce(filterList, 1000), [searchInput]);

  useEffect(() => {
    setSource(items);
  }, [items]);

  useEffect(() => {
    delayedFilterList(searchInput);
    return delayedFilterList.cancel;
  }, [searchInput]);

  return (
    <section className={cx(css.cntSubject, "container-row")}>
      {items.length > 0 && (
        <div className={cx(css.cntSubject_filter, "container-row")}>
          <Input
            placeholder="Filtrar..."
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            icon="search"
          />
        </div>
      )}
      <>
        {source && source.length ? (
          <section className={cx(css.cntTable, "container-row")}>
            <ul className={cx(css.cntHeaderList, "container-row")}>
              <li className={cx(css.cntHeaderList_item, css.cntHeaderList_item__progressBar)}>
                Progreso
              </li>
              <li className={cx(css.cntHeaderList_item, "col_1")}>Sede</li>
              <li className={cx(css.cntHeaderList_item, "col_3")}>Código</li>
              <li className={cx(css.cntHeaderList_item, "col_4")}>Nombre</li>
              <li className={cx(css.cntHeaderList_item, "col_2")}>Accesos</li>
            </ul>

            <div className="container-row">
              {source
                .filter((item: ISubject) => item.unit)
                .map((item: ISubject, indexSubjectList: number) => (
                  <SubjectListItem
                    key={indexSubjectList}
                    unit={item}
                    routes={routes}
                    filterSelected={filterSelected}
                  />
                ))}
            </div>
          </section>
        ) : (
          <p className={cx(css.cntSubject_textEmpty, "col_12")}>No hay resultados para mostrar</p>
        )}
      </>
    </section>
  );
};

export default SubjectListView;
