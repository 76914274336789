import { Program, Curriculum } from "../../../models/ISchema";
import { getSelectablePrograms } from "./filterAllPrograms";

type IOption = {
  label: string;
  value: string;
};

export const filterByProgram = (
  programs: Program[],
  department: IOption,
  modality: IOption,
  shift: IOption,
): Program[] => {
  //Filter programs by departments
  return getSelectablePrograms(programs, department, modality, shift, null);
};

export const filterByCurriculum = (
  programs: Program[],
  department: IOption,
  curriculums: Curriculum[],
  modality: IOption,
  shift: IOption,
): Curriculum[] => {
  // Filter programs by filters
  const selectablePrograms = getSelectablePrograms(programs, department, modality, shift, null);

  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.id);

  const selectableCurriculums = curriculums.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.program.id);
  });

  return selectableCurriculums;
};
