import React, { useEffect, useState } from "react";
import useEditDemand from "../graphql/useEditDemand";
import cx from "classnames";
import { IForecastContextInterface } from "../../../../../context";
import Table, { CheckboxCell, InputCell } from "../../../../../../../common/components/Table";
import Paginator from "../../../../../../../common/components/Paginator/Paginator";
import Icon from "../../../../../../../common/components/Icon/Icon";
import SearchInput from "../../../../../../../common/components/SearchInput/SearchInput";
import { useStringFilterParams } from "../../../../../../dashboard/utils/utils";
import LoaderTable from "../../../../../../../common/components/LoaderTable/LoaderTable";
import css from "./level.module.scss";

interface ICurriculumProps {
  project: any;
  forecastContext: IForecastContextInterface;
  searchTerm: string;
  isLoading: boolean;
  setDemandFilter: (page: number, searchTerm: string) => void;
}

interface IPageInfo {
  total: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  page: number;
  size: number;
}

const Level: React.FC<ICurriculumProps> = (props: ICurriculumProps) => {
  const { project, setDemandFilter, searchTerm, isLoading: isLoadingProps } = props;
  const context = props.forecastContext;
  const [data, setData] = useState(project.unit.demandsPage.items);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPaginator, setIsLoadingPaginator] = useState(false);
  const [lastRowUpdatedId, setLastRowUpdatedId] = useState<number>(null);
  const pageInfo: IPageInfo = project.unit.demandsPage.pageInfo;
  const editDemand = useEditDemand();
  const routeBack = context.routes.dashboard();
  const stringFilter = useStringFilterParams();
  let countDemandOwn = 0;

  const selectedRow = data.map((value: any) => {
    if (value.own) countDemandOwn++;
    return value.checked;
  });

  useEffect(() => {
    setData(project.unit.demandsPage.items);
  }, [project]);

  useEffect(() => {
    if (!isLoadingProps) {
      setIsLoadingPaginator(false);
      setIsLoading(false);
    }
  }, [isLoadingProps]);

  const handleSaveDemand = async () => {
    setIsLoading(true);
    try {
      const unitId = project.unit.id;
      const demandAdjustments = data.map((demand: any) => {
        return {
          demandId: demand.id,
          adjusted: demand.adjusted,
          confirmed: demand.checked,
        };
      });
      const variables = {
        unitId,
        demandAdjustments,
      };
      if (demandAdjustments.length > 0) {
        await editDemand.editDemand(variables, context);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleSaveDemand();
  }, [data]);

  const courseCell = (data: any) => {
    const demand: any = data.row.original;
    const courseId = demand?.relatedUnits?.subject?.id;
    const courseRoute = courseId ?? context?.routes.unit(courseId);

    return courseId ? (
      <a className={css.cellLink} href={`${courseRoute}${stringFilter}`}>
        {data?.value?.code}
        <span className={css.cellLink_bold}>{` ${data?.value?.name}`}</span>
      </a>
    ) : (
      <p className={css.cellNormal}>
        {data?.value?.code || ""}
        <span className={css.cellNormal_bold}>{` ${data?.value?.name}` || ""}</span>
      </p>
    );
  };

  const crosslistCell = (data: any) => {
    const demand: any = data.row.original;
    const crossListId = demand?.relatedUnits?.crosslist?.id;
    const crossListRoute = crossListId ?? context?.routes.unit(crossListId);
    return crossListId ? (
      <a className={css.cellLink} href={`${crossListRoute}${stringFilter}`}>
        {data?.value}
      </a>
    ) : (
      <p className={css.cellNormal}>{data?.value || ""}</p>
    );
  };

  const handleKeyDown = (e: any) => {
    // When press 'Enter', remove focus from the imput. Then generate the autocomplete.
    if (e.key === "Enter") e.target.blur();
  };

  const adjustedCell = (data: any) => {
    const demand: any = data.row.original;
    if (!demand?.own) return `${data.value}`;
    return (
      <InputCell
        disabled={data.state.selectedRowIds[data.row.index] || isLoading}
        value={data.value}
        maxLength={5}
        onKeyDown={handleKeyDown}
        onChange={(value: string) => {
          const inputValue = value ? parseInt(value) : 0;
          data.updateImplementedData(setData, data.row.index, "adjusted", inputValue);
          setLastRowUpdatedId(data.row.id);
        }}
      />
    );
  };

  const headerCheckedCell = (data: any) => {
    const handleChange = () => {
      const { toggleAllRowsSelected, updateImplementAllData, column, isAllRowsSelected } = data;
      const newChecked = !isAllRowsSelected;
      toggleAllRowsSelected(true);
      updateImplementAllData(setData, column.id, newChecked);
      setLastRowUpdatedId(null);
    };

    return (
      <div className={cx("container-row", "row_align--center")}>
        <CheckboxCell
          {...data.getToggleAllRowsSelectedProps({ onChange: handleChange })}
          disabled={countDemandOwn === 0 || isLoading}
        />
        <label className={css.labelCheckbox}>Confirmada</label>
      </div>
    );
  };

  const checkedCell = (data: any) => {
    const demand: any = data.row.original;
    const disabled = !demand?.own;
    const { row, column, toggleRowSelected, updateImplementedData } = data;
    const handleChange = () => {
      const value = !row.isSelected;
      toggleRowSelected(row.id, value);
      updateImplementedData(setData, row.index, column.id, value);
      setLastRowUpdatedId(row.id);
    };
    return (
      <div className={cx("container-row", "row_align--center")}>
        <CheckboxCell
          {...row.getToggleRowSelectedProps({ onChange: handleChange })}
          disabled={disabled || isLoading}
        />
        {isLoading && row.id === lastRowUpdatedId && (
          <label className={css.labelCheckbox}>Cargando ...</label>
        )}
        {!isLoading && row.id === lastRowUpdatedId && (
          <label className={cx(css.labelCheckbox, css.labelCheckbox_confirm)}>
            <Icon icon="check" className={cx(css.labelCheckbox_confirm_icon)} />
            Guardado
          </label>
        )}
      </div>
    );
  };

  const ruleCodeCell = (data: any) => {
    const demand = data.row.original;
    const { course } = demand;
    const { ruleCode } = course;

    // when isRule has only one rule code
    if (course.isRule) {
      const { relatedUnits } = demand;
      if (relatedUnits?.ruleCode?.id) {
        const ruleCoderoute = context.routes.unit(relatedUnits.ruleCode.id);
        return (
          <a
            className={css.cellLink}
            key={relatedUnits.ruleCode.code}
            href={`${ruleCoderoute}${stringFilter}`}
          >
            {relatedUnits.ruleCode.code}
          </a>
        );
      }
    }

    // when !isRule could have more that one
    return ruleCode.map((item, index) => {
      const ruleCoderoute = item.unitId ?? context?.routes.unit(item.unitId);
      return (
        <a
          className={css.cellLink}
          key={item.code + index}
          href={`${ruleCoderoute}${stringFilter}`}
        >
          {item.code}
        </a>
      );
    });
  };

  const columns: any = [
    {
      Header: "Sede",
      accessor: "course.curriculum.program.campus.code",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Asignatura",
      accessor: "course",
      minWidth: 300,
      Cell: (data: any) => courseCell(data),
    },
    {
      Header: "Lista cruzada",
      accessor: "relatedUnits.crosslist.code",
      minWidth: 150,
      maxWidth: 150,
      Cell: (data: any) => crosslistCell(data),
    },
    {
      Header: "Código Regla",
      accessor: "data",
      minWidth: 150,
      maxWidth: 150,
      Cell: (data: any) => ruleCodeCell(data),
    },
    {
      Header: "Proyectada",
      accessor: "value",
      maxWidth: 150,
    },
    {
      Header: "Ajustada",
      accessor: "adjusted",
      maxWidth: 150,
      Cell: (data: any) => adjustedCell(data),
    },
    {
      Header: (row: any) => headerCheckedCell(row),
      accessor: "checked",
      minWidth: 150,
      maxWidth: 150,
      Cell: (data: any) => checkedCell(data),
    },
  ];

  const redirectUnit = (route: string) => (window.location.href = `${route}${stringFilter}`);

  return (
    <section className={cx(css.cntLevel, "container-row")}>
      <a
        className={cx(css.back, "container-row", "row_align--center")}
        href={`${routeBack}${stringFilter}`}
      >
        <Icon icon="arrow-left" className={cx(css.back_icon)} />
        volver al inicio
      </a>
      <h1 className={cx(css.title, "col_12")}>
        <span className={css.title_detail}>{project.unit.curriculum.program?.code}</span>
        <span>{` ${project.unit.curriculum.program?.name} | `}</span>
        <span className={cx(css.title_detail, css.title_detail__lower)}>Currículo</span>
        <span className={css.title_detail}>{` ${project.unit.curriculum?.code}`}</span>
      </h1>
      <div className="container-row row_aling--center">
        <nav className={css.cntNav}>
          <h2 className={cx(css.cntNav_title)}>Nivel {project.unit.label}</h2>
          <div className={cx(css.buttonsNav)}>
            <button
              className={cx(css.button)}
              disabled={project?.unit?.previusUnit ? false : true}
              onClick={() => redirectUnit(context.routes.unit(project.unit.previusUnit))}
            >
              <Icon icon="arrow-left" className={cx(css.button_icon)} />
            </button>
            <button
              className={cx(css.button)}
              disabled={project?.unit?.nextUnit ? false : true}
              onClick={() => redirectUnit(context.routes.unit(project.unit.nextUnit))}
            >
              <Icon icon="arrow-right" className={cx(css.button_icon)} />
            </button>
          </div>
        </nav>
        <div className={cx("col_2", css.cntSearchInput)}>
          <SearchInput
            placeholder="Filtrar"
            value={searchTerm}
            onClick={inputValue => {
              setIsLoading(true);
              setDemandFilter(1, inputValue);
            }}
          />
        </div>
        {isLoading && (
          <div className={cx("col_1", css.cntSearchInputLoader)}>
            <LoaderTable />
          </div>
        )}
      </div>

      <Table columns={columns} data={data} initSelectedRowIds={selectedRow} />

      <div className={cx(css.cntLevel_save, "container-row", "row--center")}>
        <Paginator
          total={pageInfo.total}
          pageSize={pageInfo.size}
          current={pageInfo.page}
          onChange={currentPage => {
            setIsLoadingPaginator(true);
            setDemandFilter(currentPage, "");
          }}
        />
        {isLoadingPaginator && (
          <div className={cx(css.cntPaginatorLoader)}>
            <LoaderTable />
          </div>
        )}
      </div>
    </section>
  );
};

export default Level;
