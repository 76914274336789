import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import getUnitDetail from "./graphql/unitDetail";
import Level from "./level/Level";
import CrossList from "./crosslist/CrossList";
import SubjectList from "./subject/SubjectList";
import RuleCode from "./ruleCode/RuleCode";
import { Project, UnitKindEnum } from "../../../../../../models/ISchema";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import { debounce } from "lodash";

type TParams = {
  workspace: string | undefined;
  project: string | undefined;
  scenario: string | undefined;
  unitId: string | undefined;
};

interface IEditor {
  forecastContext: IForecastContextInterface;
}

const Editor: React.FC<IEditor> = (props: IEditor) => {
  const params: TParams = useParams();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dataProject, setDataProject] = useState<Project>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const client = useApolloClient();

  const getDemands = async (newPage: number, newSearchTerm: string) => {
    setIsLoading(true);
    try {
      const { data } = await client.query({
        query: getUnitDetail,
        variables: {
          projectId: params.project,
          unitId: params.unitId,
          demandFilter: {
            page: newPage,
            searchTerm: newSearchTerm,
          },
        },
        fetchPolicy: "no-cache",
      });
      setDataProject({ ...data?.project });
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const getDemandsDelayed = async () => {
    await getDemands(page, searchTerm);
  };

  const delayedQuery = useCallback(debounce(getDemandsDelayed, 1000), [searchTerm]);

  const setDemandFilter = (newPage: number, newSearchTerm: string) => {
    if (newPage === page) {
      setSearchTerm(newSearchTerm);
    } else {
      setPage(newPage);
    }
  };

  useEffect(() => {
    getDemands(page, searchTerm);
  }, [page]);

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [searchTerm]);

  if (dataProject) {
    switch (dataProject?.unit?.kind) {
      case UnitKindEnum.Level:
        return (
          <Level
            isLoading={isLoading}
            project={dataProject}
            forecastContext={props.forecastContext}
            setDemandFilter={setDemandFilter}
            searchTerm={searchTerm}
          />
        );
      case UnitKindEnum.Crosslist:
        return (
          <CrossList
            isLoading={isLoading}
            project={dataProject}
            forecastContext={props.forecastContext}
            setDemandFilter={setDemandFilter}
            searchTerm={searchTerm}
          />
        );
      case UnitKindEnum.Subject:
        return (
          <SubjectList
            isLoading={isLoading}
            project={dataProject}
            forecastContext={props.forecastContext}
            setDemandFilter={setDemandFilter}
            searchTerm={searchTerm}
          />
        );
      case UnitKindEnum.RuleCode:
        return (
          <RuleCode
            isLoading={isLoading}
            project={dataProject}
            forecastContext={props.forecastContext}
            setDemandFilter={setDemandFilter}
            searchTerm={searchTerm}
          />
        );
      default:
        console.debug("not found: UnitKind");
        return null;
    }
  }

  return null;
};

export default withForecastContext(Editor);
