import * as React from "react";
import Modal from "react-modal";
import cx from "classnames";
import css from "./modal.module.scss";
import { ReactNode } from "react";

interface IPropsModal {
  title?: string;
  show: boolean;
  onClose: Function;
  headerColor?: string;
  header?: any;
  children?: ReactNode;
}

interface IStateModal {
  show: boolean;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "0px solid rgb(204, 204, 204)",
    borderRadius: 8,
    overlfow: "auto",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 10,
  },
};

Modal.setAppElement("#root");

class ExportModal extends React.PureComponent<IPropsModal, IStateModal> {
  render(): JSX.Element {
    const { children, title, show, onClose, headerColor, header } = this.props;
    return (
      <Modal isOpen={show} contentLabel={title} style={customStyles}>
        <section className={css.modal_content}>
          <header className={css.modal_header} style={{ background: headerColor }}>
            <div className={css.modal_header_title}>
              <div>{header ? header() : title}</div>
            </div>
          </header>

          <div className={css.modal_content_detail}>{children}</div>
        </section>
      </Modal>
    );
  }
}

export default ExportModal;
