import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import Forecast from "../../../../../routes/Forecast";
import {
  ILevel,
  IFilterSelected,
} from "../../../../forecast/modules/demand-editor/pages/dashboard/models";
import { useGenerateStringFilter } from "../../../utils/utils";
import ProgressBar from "../../../../../common/components/ProgressBar/ProgressBar";
import css from "./levelView.module.scss";

interface ILevelProps {
  level: ILevel;
  className?: string;
  routes: Forecast;
  filterSelected?: IFilterSelected;
}

const LevelItem: React.FunctionComponent<ILevelProps> = (props: ILevelProps) => {
  const { level, className, filterSelected } = props;
  const { campus, department, program, curriculum, modality, shift } = filterSelected;

  const stringFilter = useGenerateStringFilter(
    campus,
    department,
    program,
    curriculum,
    modality,
    shift,
    "0",
  );
  return (
    <Link
      to={`${props.routes.unit(level.unit.id)}${stringFilter}`}
      className={cx(css.cntLevelProgress, className, "col_2")}
    >
      <span className={css.cntLevelProgress_level}>{level.level}</span>
      <ProgressBar progress={level.unit.progress} />
    </Link>
  );
};

export default LevelItem;
