import React from "react";
import { useQuery } from "react-apollo";
import { GET_WORKSPACES } from "./context.queries";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { Workspace } from "../../../../../models/ISchema";

interface ISelectProps {
  workspaceValue: any;
  defaultWorkspaceId?: string;
  onChange: Function;
}

const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

const WorkspaceSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
  const { workspaceValue, onChange, defaultWorkspaceId } = props;
  const { loading, error, data } = useQuery<any, any>(GET_WORKSPACES, {
    variables: {},
    fetchPolicy: "no-cache",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error)
    return (
      <div style={{ padding: "10px 0" }}>
        <Select
          options={[]}
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#e5e8ec",
              primary: "#6993c9",
              neutral80: "#575d62",
              neutral90: "#575d62",
            },
          })}
          placeholder="No hay datos..."
        />
      </div>
    );

  const workspaces: Array<Workspace> = data.workspaces;
  const options = workspaces.map((workspace: Workspace) => {
    return { label: workspace.name, value: workspace.id };
  });

  const selectedValue = options.find(option => {
    if (workspaceValue && workspaceValue.value) return workspaceValue.value === option.value;
    if (defaultWorkspaceId) return defaultWorkspaceId === option.value;
    return false;
  });

  if (!workspaceValue && selectedValue) onChange(selectedValue);

  return (
    <div style={{ padding: "10px 0" }}>
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={selectedValue || null}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};
export default WorkspaceSelect;
