import React, { Component } from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { Subscription } from "react-apollo";

import gql from "graphql-tag";
import { AuthConsumer } from "../../common/contexts/AuthContext";
import { ForecastContextProvider } from "./context";
import { Message } from "semantic-ui-react";
import ProjectStatus from "./modules/demand-editor/pages/project-status";
import DefaultLayout from "../../common/components/layout/DefaultLayout";
import ForecastRoutes from "../../routes/Forecast";
import { IRouteType } from "../../routes/Routes";
import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
};

const PROJECT_SUSCRIPTION = gql`
  subscription onProjectStateChange($projectId: ID!) {
    migratedProject(projectId: $projectId) {
      id
      active
      state
      description
    }
  }
`;

const test = localStorage.getItem("__darwined_user_data");
const userData = test ? JSON.parse(localStorage.getItem("__darwined_user_data")) : {};
const workspaceId = userData?.project?.access?.workspaceId;
const scenarioId = userData?.project?.access?.scenarioId;
const projectId = userData?.project?.id;

// init context
const routes = new ForecastRoutes({
  workspace: workspaceId,
  scenario: scenarioId,
  project: projectId,
});
const modules: IRouteType[] = [
  routes.routeProjectStatus(),
  routes.routeCrosslistEdit(),
  routes.routeCrosslistAdd(),
  routes.routeCrossList(),
  routes.routeProjectAdd(),
  routes.routeProjectEdit(),
  routes.routeProjectList(),
  routes.routeUnit(),
  routes.routeDashboard(),
  {
    path: "/forecast",
    name: "forecast@home",
    permissions: "public",
  },
];

class Forecast extends Component<any> {
  state = {
    value: {
      updateDashboardCurrentData: {} as any,
      dashboard: {} as any,
      currentProject: JSON.parse(localStorage.getItem("__darwined_user_data") || ""),
    },
    forecastRoutes: routes,
  };

  componentDidMount() {
    this.setState({
      value: {
        updateDashboardCurrentData: this.updateDashboard,
        dashboard: {
          filterSearchState: "PRISTINE",
          currentDepartmentList: [],
          currentDepartmentListSelected: { text: null, value: null, key: null },
          currentModalityList: [],
          currentModalityListSelected: { text: null, value: null, key: null },
          currentShiftList: [],
          currentShiftListSelected: { text: null, value: null, key: null },
          currentProgramList: [],
          currentProgramListSelected: { text: null, value: null, key: null },
          currentCurriculumList: [],
          currentCurriculumListSelected: { text: null, value: null, key: null },
          unitView: {
            levelView: null,
            subjectView: null,
            crosslistView: null,
          },
          currentUnitTitle: null,
          loadingSearch: false,
        },
        currentProject: this.state.value.currentProject.project
          ? this.state.value.currentProject.project
          : null,
        updateCurrentProject: this.updateCurrentProject,
      },
    });
  }

  updateCurrentProject = (project: any) => {
    if (this.state.value.currentProject !== project) {
      this.setState({ ...this.state, value: { ...this.state.value, currentProject: project } });
    }
  };

  updateDashboard = (type: string, payload: any) => {
    if (this.state.value.dashboard[type] !== payload) {
      const dashboard = this.state.value.dashboard;

      switch (type) {
        case "unitView":
        case "currentProgramListSelected":
        case "currentCurriculumListSelected":
        case "currentModalityListSelected":
        case "currentShiftListSelected":
        case "currentProgramList":
        case "currentModalityList":
        case "currentShiftList":
        case "currentUnitTitle":
        case "loadingSearch":
        case "filterSearchState":
          this.setState({
            value: {
              ...this.state.value,
              dashboard: {
                ...this.state.value.dashboard,
                [type]: payload,
              },
            },
          });
          break;
        case "currentDepartmentListSelected":
          this.setState({
            value: {
              ...this.state.value,
              dashboard: {
                ...this.state.value.dashboard,
                currentDepartmentListSelected: payload["selectedValue"],
                currentProgramList:
                  "newProgramList" in payload
                    ? payload["newProgramList"]
                    : dashboard.currentProgramList,
                currentModalityList:
                  "selectableModalities" in payload
                    ? payload["selectableModalities"]
                    : dashboard.currentModalityList,
                currentProgramListSelected: { text: "Todas las carreras", value: "*", key: "*" },
                currentCurriculumListSelected: {
                  text: "Todos los currículos",
                  value: "*",
                  key: "*",
                },
              },
            },
          });
          break;
      }
    }
  };

  updateRoutesForecast = (forecastRoutes: ForecastRoutes) => {
    this.setState({ forecastRoutes });
  };

  render() {
    const { currentProject } = this.state.value;
    return (
      <AuthConsumer>
        {(authContext: any) => (
          <>
            <>
              <ForecastContextProvider
                value={{
                  ...this.state.value,
                  projectId: currentProject && currentProject.id ? currentProject.id : "",
                  auth: authContext.auth,
                  routes: this.state.forecastRoutes,
                  updateRoutesForecast: this.updateRoutesForecast,
                }}
              >
                <>
                  <DefaultLayout context={authContext} routes={this.state.forecastRoutes}>
                    <React.Fragment>
                      <ToastContainer />
                      {authContext.auth &&
                      authContext.auth.token &&
                      currentProject &&
                      currentProject.id ? (
                        <>
                          <Subscription
                            subscription={PROJECT_SUSCRIPTION}
                            variables={{ projectId: currentProject.id }}
                            onSubscriptionData={(response: any) => {
                              const data = response.subscriptionData.data;

                              // PROJECT SUBSCRIPTION ---->

                              if (data && data.migratedProject) {
                                const project = {
                                  id: data.migratedProject.id,
                                  active: data.migratedProject.active,
                                  state: data.migratedProject.state,
                                  description: data.migratedProject.description,
                                };

                                const currentStorage = JSON.parse(
                                  localStorage.__darwined_user_data,
                                );
                                const prevStateName = "" + currentStorage.project.state;

                                if (project !== currentStorage.project) {
                                  currentStorage["project"] = project;
                                  localStorage.setItem(
                                    "__darwined_user_data",
                                    JSON.stringify(currentStorage),
                                  );

                                  const confirmationMsg: any = {
                                    EXPORT: "El proyecto ha sido exportado correctamente",
                                    SYNC: "El proyecto ha sido sincronizado correctamente",
                                    MIGRATE: "El proyecto ha sido creado correctamente",
                                    BLOCK: "El proyecto ha sido creado correctamente",
                                  };

                                  if (prevStateName === "EXPORT") {
                                    window.location.href = routes.dashboard();
                                  }

                                  if (prevStateName === "SYNC") {
                                    window.location.href = routes.dashboard();
                                  }

                                  if (prevStateName === "MIGRATE") {
                                    window.location.href = routes.dashboard();
                                  }

                                  if (prevStateName === "BLOCK") {
                                    window.location.href = routes.dashboard();
                                  }

                                  this.forceUpdate();
                                  this.updateCurrentProject(project);
                                  toast.info(confirmationMsg[prevStateName], TOAST_OPTIONS);
                                }
                              }

                              // <---- PROJECT SUBSCRIPTION
                            }}
                          >
                            {() => {
                              return (
                                <>
                                  {currentProject && currentProject.id && currentProject.active ? (
                                    <Switch>
                                      {currentProject.state === "READY" ? (
                                        modules.map((route: any) => {
                                          if (route.permissions === "public") {
                                            return (
                                              <Route
                                                exact
                                                key={route.path}
                                                path={route.path}
                                                component={route.component}
                                              />
                                            );
                                          } else if (
                                            authContext.auth.role.role === "admin"
                                            //  ( route.name.includes("cleditor") &&
                                            //   authContext.auth.role.permissions.crosslist.includes("create") )
                                          ) {
                                            return (
                                              <Route
                                                exact
                                                key={route.path}
                                                path={route.path}
                                                component={route.component}
                                              />
                                            );
                                          } else {
                                            return <></>;
                                          }
                                        })
                                      ) : (
                                        <ProjectStatus />
                                      )}
                                    </Switch>
                                  ) : (
                                    <>
                                      {authContext.auth.role.role === "admin" ? (
                                        <>
                                          {/* // Provider only with project route */}
                                          <Switch>
                                            <Route exact={true} {...routes.routeProjectList()} />
                                            <Route exact={true} {...routes.routeProjectEdit()} />
                                            <Route exact={true} {...routes.routeProjectAdd()} />
                                            <Route exact={true} {...routes.routeProjectStatus()} />
                                            <Route
                                              path="*"
                                              exact={true}
                                              component={() => (
                                                <Message>
                                                  <Message.Header>
                                                    No hay un proyecto activo actualmente
                                                  </Message.Header>
                                                  <p>
                                                    Puedes dirigirte a la sección{" "}
                                                    <Link to={routes.projectList()}>
                                                      <strong>proyectos</strong>
                                                    </Link>
                                                  </p>
                                                </Message>
                                              )}
                                            />
                                          </Switch>
                                        </>
                                      ) : (
                                        <Message>
                                          <Message.Header>
                                            No hay un proyecto activo actualmente
                                          </Message.Header>
                                          <p>Contacte al administrador del sistema</p>
                                        </Message>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            }}
                          </Subscription>
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  </DefaultLayout>
                </>
              </ForecastContextProvider>
            </>
          </>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(Forecast);
