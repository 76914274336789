import { Program } from "../../../models/ISchema";

type IOption = {
  label: string;
  value: string;
};

export const getSelectablePrograms = (
  programs: Program[],
  department: IOption,
  modality: IOption,
  shift: IOption,
  program: IOption,
) => {
  let selectablePrograms: Program[] = [];
  if (department?.value) {
    selectablePrograms = programs.filter(item =>
      department ? item.department.id === department.value : false,
    );
    if (modality?.value) {
      selectablePrograms = selectablePrograms.filter(item =>
        department ? item.modality.id === modality.value : false,
      );
    }
    if (shift?.value) {
      selectablePrograms = selectablePrograms.filter(item =>
        department ? item.shift.id === shift.value : false,
      );
    }
    if (program?.value) {
      selectablePrograms = selectablePrograms.filter(item =>
        department ? item.id === program.value : false,
      );
    }
  }
  return selectablePrograms;
};
