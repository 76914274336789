export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Auth = {
  __typename?: "Auth";
  project?: Maybe<Project>;
  status?: Maybe<Status>;
  user?: Maybe<User>;
};

export type Campus = {
  __typename?: "Campus";
  code?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type Course = {
  __typename?: "Course";
  code?: Maybe<Scalars["String"]>;
  curriculum: Curriculum;
  id: Scalars["ID"];
  isRule?: Maybe<Scalars["Boolean"]>;
  label?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  ruleCode?: Maybe<Array<Maybe<RuleCodeUnit>>>;
};

export type CrosslistViewResult = {
  __typename?: "CrosslistViewResult";
  crosslist?: Maybe<Scalars["String"]>;
  unit: Unit;
};

export type Curriculum = {
  __typename?: "Curriculum";
  code?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  program?: Maybe<Program>;
};

export type DarwinContext = {
  __typename?: "DarwinContext";
  originId?: Maybe<Scalars["ID"]>;
  scenarioId?: Maybe<Scalars["ID"]>;
  workspaceId?: Maybe<Scalars["ID"]>;
};

export type Dashboard = {
  __typename?: "Dashboard";
  crosslistView?: Maybe<Array<CrosslistViewResult>>;
  levelView?: Maybe<Array<LevelViewResult>>;
  subjectView?: Maybe<Array<SubjectViewResult>>;
};

export type DashboardCrosslistViewArgs = {
  filter: ViewFilter;
};

export type DashboardLevelViewArgs = {
  filter: ViewFilter;
};

export type DashboardSubjectViewArgs = {
  filter: ViewFilter;
};

export type Demand = {
  __typename?: "Demand";
  adjusted?: Maybe<Scalars["Int"]>;
  checked?: Maybe<Scalars["Boolean"]>;
  course: Course;
  courseCode?: Maybe<Scalars["String"]>;
  courseLabel?: Maybe<Scalars["String"]>;
  curriculumName?: Maybe<Scalars["String"]>;
  equivalenceGroup?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  own?: Maybe<Scalars["Boolean"]>;
  programName?: Maybe<Scalars["String"]>;
  relatedUnits?: Maybe<RelatedUnits>;
  user?: Maybe<User>;
  value?: Maybe<Scalars["Int"]>;
};

export type DemandAdjustment = {
  adjusted?: Maybe<Scalars["Int"]>;
  confirmed?: Maybe<Scalars["Boolean"]>;
  demandId: Scalars["ID"];
};

export type DemandPage = {
  __typename?: "DemandPage";
  items?: Maybe<Array<Demand>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DemandSearch = {
  __typename?: "DemandSearch";
  adjusted?: Maybe<Scalars["Int"]>;
  courseCode?: Maybe<Scalars["String"]>;
  courseLevel?: Maybe<Scalars["Int"]>;
  courseName?: Maybe<Scalars["String"]>;
  crosslist?: Maybe<Scalars["String"]>;
  curriculumCode?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  programCode?: Maybe<Scalars["String"]>;
  programName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type DemandSearchPage = {
  __typename?: "DemandSearchPage";
  items?: Maybe<Array<Maybe<DemandSearch>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DemandUnitInput = {
  adjusted?: Maybe<Scalars["Int"]>;
  checked?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  value?: Maybe<Scalars["Int"]>;
};

export type Department = {
  __typename?: "Department";
  campus?: Maybe<Campus>;
  code?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type Institution = {
  __typename?: "Institution";
  code?: Maybe<Scalars["String"]>;
  darwinUrl: Scalars["String"];
  domain?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
};

export type LevelViewResult = {
  __typename?: "LevelViewResult";
  curriculum?: Maybe<Curriculum>;
  level?: Maybe<Scalars["Int"]>;
  unit: Unit;
};

export type Modality = {
  __typename?: "Modality";
  code?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type Module = {
  __typename?: "Module";
  accesible?: Maybe<Scalars["Boolean"]>;
  code?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Origin = {
  __typename?: "Origin";
  demandCount?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
};

/** Information about a page of items. Usually bound to another type. */
export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

/** Input for handling pagination */
export type PaginationInput = {
  page: Scalars["Int"];
  searchTerm?: Maybe<Scalars["String"]>;
  size: Scalars["Int"];
};

export type Permissions = {
  __typename?: "Permissions";
  approveCrosslist?: Maybe<Scalars["Boolean"]>;
  crosslist?: Maybe<Array<Maybe<Scalars["String"]>>>;
  crosslistManagement?: Maybe<Scalars["Boolean"]>;
  projectManagement?: Maybe<Scalars["Boolean"]>;
};

export type Program = {
  __typename?: "Program";
  campus?: Maybe<Campus>;
  code?: Maybe<Scalars["String"]>;
  department?: Maybe<Department>;
  id: Scalars["ID"];
  modality?: Maybe<Modality>;
  name?: Maybe<Scalars["String"]>;
  shift?: Maybe<Shift>;
};

export type Project = {
  __typename?: "Project";
  access?: Maybe<ProjectAccess>;
  active?: Maybe<Scalars["Boolean"]>;
  campuses?: Maybe<Array<Maybe<Campus>>>;
  curriculums?: Maybe<Array<Maybe<Curriculum>>>;
  dashboard?: Maybe<Dashboard>;
  departments?: Maybe<Array<Maybe<Department>>>;
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  institutionId: Scalars["ID"];
  logoutUrl?: Maybe<Scalars["String"]>;
  modalities?: Maybe<Array<Maybe<Modality>>>;
  programs?: Maybe<Array<Maybe<Program>>>;
  searchByDemand?: Maybe<Array<Maybe<Demand>>>;
  shifts?: Maybe<Array<Maybe<Shift>>>;
  startDate?: Maybe<Scalars["String"]>;
  state?: Maybe<ProjectStateEnum>;
  title?: Maybe<Scalars["String"]>;
  unit?: Maybe<Unit>;
};

export type ProjectSearchByDemandArgs = {
  q: Scalars["String"];
};

export type ProjectUnitArgs = {
  unitId: Scalars["ID"];
};

export type ProjectAccess = {
  __typename?: "ProjectAccess";
  originId: Scalars["ID"];
  originName?: Maybe<Scalars["String"]>;
  scenarioId: Scalars["ID"];
  scenarioName?: Maybe<Scalars["String"]>;
  workspaceId: Scalars["ID"];
  workspaceName?: Maybe<Scalars["String"]>;
};

export type ProjectAccessInput = {
  originId?: Maybe<Scalars["ID"]>;
  originName?: Maybe<Scalars["String"]>;
  scenarioId?: Maybe<Scalars["ID"]>;
  scenarioName?: Maybe<Scalars["String"]>;
  workspaceId?: Maybe<Scalars["ID"]>;
  workspaceName?: Maybe<Scalars["String"]>;
};

export type ProjectInput = {
  access?: Maybe<ProjectAccessInput>;
  active?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  institutionId?: Maybe<Scalars["ID"]>;
  startDate?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ProjectResponse = {
  __typename?: "ProjectResponse";
  project?: Maybe<Project>;
  status: Status;
};

export enum ProjectStateEnum {
  Block = "BLOCK",
  Export = "EXPORT",
  Migrate = "MIGRATE",
  Ready = "READY",
  Sync = "SYNC",
}

export type RelatedUnitItem = {
  __typename?: "RelatedUnitItem";
  code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

export type RelatedUnits = {
  __typename?: "RelatedUnits";
  crosslist?: Maybe<RelatedUnitItem>;
  level?: Maybe<RelatedUnitItem>;
  ruleCode?: Maybe<RelatedUnitItem>;
  subject?: Maybe<RelatedUnitItem>;
};

export type Report = {
  __typename?: "Report";
  projectId: Scalars["ID"];
  type: ReportType;
  url?: Maybe<Scalars["String"]>;
};

export enum ReportType {
  DemandAdjustment = "DEMAND_ADJUSTMENT",
  EnrollmentsAnalysis = "ENROLLMENTS_ANALYSIS",
  ForecastStatistics = "FORECAST_STATISTICS",
}

export enum ResultCode {
  CouldNotCreateUnit = "COULD_NOT_CREATE_UNIT",
  /** The given label is already related to another crosslist */
  CrosslistLabelAlreadyExist = "CROSSLIST_LABEL_ALREADY_EXIST",
  /** Demand is already related to another crosslist */
  DemandAlreadyInCrosslist = "DEMAND_ALREADY_IN_CROSSLIST",
  /** Demand in crosslist is empty */
  EmptyDemand = "EMPTY_DEMAND",
  ErrorCreatingList = "ERROR_CREATING_LIST",
  ErrorProjectSync = "ERROR_PROJECT_SYNC",
  ErrorUpdateDemand = "ERROR_UPDATE_DEMAND",
  ErrorUpdateUnit = "ERROR_UPDATE_UNIT",
  ErrorUpdatingTags = "ERROR_UPDATING_TAGS",
  /** No project found with given id */
  InvalidProject = "INVALID_PROJECT",
  /** The token is invalid (user not found) */
  InvalidToken = "INVALID_TOKEN",
  /** No unit found with given id */
  InvalidUnit = "INVALID_UNIT",
  /** Demands deleted from units */
  PopDemands = "POP_DEMANDS",
  ProjectExporting = "PROJECT_EXPORTING",
  ProjectInSync = "PROJECT_IN_SYNC",
  ProjectMigrating = "PROJECT_MIGRATING",
  Success = "SUCCESS",
  SuccessDeleted = "SUCCESS_DELETED",
  UserNotFound = "USER_NOT_FOUND",
  /** When the unit demand distribution is not pass the check validation */
  WrongDemandDistribution = "WRONG_DEMAND_DISTRIBUTION",
}

export type Role = {
  __typename?: "Role";
  code?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  permissions?: Maybe<Permissions>;
  /** @deprecated rename to code */
  role?: Maybe<Scalars["String"]>;
};

export type RootMutationType = {
  __typename?: "RootMutationType";
  addProject?: Maybe<ProjectResponse>;
  addUnit: UnitResponse;
  changeProjectStatus?: Maybe<Project>;
  confirmUnit: UnitResponse;
  editConfirmCrosslist: Unit;
  editDemand: Unit;
  editProject?: Maybe<Project>;
  editUnit: UnitResponse;
  editUnitDemandDistribution: UnitResponse;
  exportDemands: ProjectResponse;
  report: Report;
  syncOriginData: ProjectResponse;
};

export type RootMutationTypeAddProjectArgs = {
  projectInput: ProjectInput;
};

export type RootMutationTypeAddUnitArgs = {
  unitInput?: Maybe<UnitInput>;
};

export type RootMutationTypeChangeProjectStatusArgs = {
  projectId: Scalars["ID"];
  status: Scalars["Boolean"];
};

export type RootMutationTypeConfirmUnitArgs = {
  confirmed?: Maybe<Scalars["Boolean"]>;
  unitId: Scalars["ID"];
};

export type RootMutationTypeEditConfirmCrosslistArgs = {
  crosslistConfirmation?: Maybe<Scalars["Boolean"]>;
  unitId: Scalars["ID"];
};

export type RootMutationTypeEditDemandArgs = {
  demandAdjustments?: Maybe<Array<Maybe<DemandAdjustment>>>;
  unitId: Scalars["ID"];
};

export type RootMutationTypeEditProjectArgs = {
  projectId: Scalars["ID"];
  projectInput: ProjectInput;
};

export type RootMutationTypeEditUnitArgs = {
  unitId: Scalars["ID"];
  unitInput?: Maybe<UnitInput>;
};

export type RootMutationTypeEditUnitDemandDistributionArgs = {
  demandDistribution: Array<Maybe<TagInput>>;
  unitId: Scalars["ID"];
};

export type RootMutationTypeExportDemandsArgs = {
  projectId: Scalars["ID"];
};

export type RootMutationTypeReportArgs = {
  projectId: Scalars["ID"];
  type: ReportType;
};

export type RootMutationTypeSyncOriginDataArgs = {
  projectId: Scalars["ID"];
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  auth?: Maybe<Auth>;
  demandSearch?: Maybe<DemandSearchPage>;
  origins?: Maybe<Array<Maybe<Origin>>>;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Maybe<Project>>>;
  scenarios?: Maybe<Array<Maybe<Scenario>>>;
  status?: Maybe<Status>;
  /** Queries for the sys context */
  sys: SysQueries;
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
};

export type RootQueryTypeDemandSearchArgs = {
  filter: PaginationInput;
  projectId: Scalars["ID"];
};

export type RootQueryTypeOriginsArgs = {
  scenarioId: Scalars["ID"];
};

export type RootQueryTypeProjectArgs = {
  projectId: Scalars["ID"];
};

export type RootQueryTypeProjectsArgs = {
  filter?: Maybe<ProjectInput>;
  institutionId: Scalars["ID"];
};

export type RootQueryTypeScenariosArgs = {
  workspaceId: Scalars["ID"];
};

export type RootSubscriptionType = {
  __typename?: "RootSubscriptionType";
  migratedProject: Project;
};

export type RootSubscriptionTypeMigratedProjectArgs = {
  projectId: Scalars["ID"];
};

export type RuleCodeUnit = {
  __typename?: "RuleCodeUnit";
  code?: Maybe<Scalars["String"]>;
  unitId?: Maybe<Scalars["ID"]>;
};

export type Scenario = {
  __typename?: "Scenario";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  workspace?: Maybe<Workspace>;
};

export type Shift = {
  __typename?: "Shift";
  code?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type Status = {
  __typename?: "Status";
  code?: Maybe<Scalars["Int"]>;
  msg?: Maybe<ResultCode>;
};

export type SubjectViewResult = {
  __typename?: "SubjectViewResult";
  subject?: Maybe<Scalars["String"]>;
  unit: Unit;
};

export type SysQueries = {
  __typename?: "SysQueries";
  /** Get the current institution data */
  institution?: Maybe<Institution>;
};

export type SysQueriesInstitutionArgs = {
  id: Scalars["ID"];
};

export type TagInput = {
  tagId: Scalars["ID"];
  value?: Maybe<Scalars["Int"]>;
};

export type Unit = {
  __typename?: "Unit";
  access?: Maybe<Array<Maybe<Scalars["String"]>>>;
  campusName?: Maybe<Array<Maybe<Scalars["String"]>>>;
  checkedCount?: Maybe<Scalars["Int"]>;
  confimed?: Maybe<Scalars["Boolean"]>;
  /** @deprecated rename to confirmed */
  crosslistConfirmation?: Maybe<Scalars["Boolean"]>;
  curriculum?: Maybe<Curriculum>;
  curriculumName?: Maybe<Scalars["String"]>;
  demandDistribution?: Maybe<Array<Maybe<UnitTag>>>;
  demands?: Maybe<Array<Maybe<Demand>>>;
  demandsPage?: Maybe<DemandPage>;
  department?: Maybe<Department>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  kind?: Maybe<UnitKindEnum>;
  label?: Maybe<Scalars["String"]>;
  nextUnit?: Maybe<Scalars["ID"]>;
  previusUnit?: Maybe<Scalars["ID"]>;
  programName?: Maybe<Scalars["String"]>;
  progress?: Maybe<Scalars["Float"]>;
  tags?: Maybe<Scalars["String"]>;
  totalAdjusted?: Maybe<Scalars["Int"]>;
  totalDemand?: Maybe<Scalars["Int"]>;
  totalProjected?: Maybe<Scalars["Int"]>;
};

export type UnitDemandsPageArgs = {
  demandFilter: PaginationInput;
};

export type UnitInput = {
  curriculumId?: Maybe<Scalars["ID"]>;
  demands: Array<DemandUnitInput>;
  departmentId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  kind: UnitKindEnum;
  label: Scalars["String"];
  projectId: Scalars["ID"];
  shiftId?: Maybe<Scalars["ID"]>;
};

export enum UnitKindEnum {
  Crosslist = "CROSSLIST",
  Level = "LEVEL",
  RuleCode = "RULE_CODE",
  Subject = "SUBJECT",
}

export type UnitResponse = {
  __typename?: "UnitResponse";
  status?: Maybe<Status>;
  unit?: Maybe<Unit>;
};

export type UnitTag = {
  __typename?: "UnitTag";
  label: Scalars["String"];
  name: Scalars["String"];
  tagId: Scalars["ID"];
  value?: Maybe<Scalars["Int"]>;
};

export type User = {
  __typename?: "User";
  context?: Maybe<DarwinContext>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  institution?: Maybe<Institution>;
  institutionId?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  logoutUrl?: Maybe<Scalars["String"]>;
  modules?: Maybe<Array<Maybe<Module>>>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Role>;
  username?: Maybe<Scalars["String"]>;
};

export type ViewFilter = {
  campusId?: Maybe<Scalars["ID"]>;
  curriculumId?: Maybe<Scalars["ID"]>;
  departmentId?: Maybe<Scalars["ID"]>;
  level?: Maybe<Scalars["Int"]>;
  modalityId?: Maybe<Scalars["ID"]>;
  programId?: Maybe<Scalars["ID"]>;
  shiftId?: Maybe<Scalars["ID"]>;
};

export type Workspace = {
  __typename?: "Workspace";
  code: Scalars["String"];
  id: Scalars["ID"];
  institution?: Maybe<Institution>;
  name: Scalars["String"];
};
