import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { Input } from "@foris/avocado-ui";
import css from "./headerDemand.module.scss";
import Icon from "../../../../../../../common/components/Icon/Icon";
import EDIT_DEMANT_DISTRIBUTION from "../graphql/editDemandDistribution";
import fakeAuth from "../../../../../../../authClient";

interface IHeaderDemandProps {
  totalAdjusted: number;
  totalProjected: number;
  demandDistribution: Array<any>;
  projectId: string;
  disabled?: boolean;
  onChangeDemands?: (getApertureDifference: number) => void;
}

interface IInputTag {
  tagId: string;
  name: string;
  label: string;
  value?: number;
}

const HeaderDemand = (props: IHeaderDemandProps) => {
  const {
    totalAdjusted,
    totalProjected,
    demandDistribution,
    projectId,
    onChangeDemands,
    disabled,
  } = props;
  const [tags, setTags] = useState<Array<IInputTag>>(demandDistribution);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editDemandDistribution] = useMutation(EDIT_DEMANT_DISTRIBUTION);

  const userHasEditPermission =
    fakeAuth.getUserData().role.permissions?.crosslist?.some((item: any) => item === "update") &&
    tags.length > 0;

  const getTotalAperture =
    tags.length > 0
      ? tags.reduce((totalAcum, tag) => {
          const total = totalAcum.value || 0;
          return {
            ...tag,
            value: tag.value + total || total,
          };
        }).value
      : undefined;

  const getApertureDifference =
    getTotalAperture !== null || getTotalAperture === 0 ? getTotalAperture - totalAdjusted : 0;

  const updateTags = (newTag: IInputTag) => {
    const newTags = tags.map(tag => (newTag.tagId === tag.tagId ? newTag : tag));
    setTags(newTags);
  };

  const handleInputTagChange = (tag: IInputTag, event: any) => {
    const newTagValue = event.target.value.replace(/\D/, "");
    const newTag = {
      ...tag,
      value: newTagValue ? parseInt(newTagValue) : 0,
    };
    updateTags(newTag);
  };

  const updateDemandDistribution = async () => {
    const formatDemandDistribution = tags.map(tag => ({ tagId: tag.tagId, value: tag.value }));
    setIsLoading(() => true);
    try {
      await editDemandDistribution({
        variables: {
          unitId: projectId,
          demandDistribution: formatDemandDistribution,
        },
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleKeyDown = (e: any) => {
    // When press 'Enter', remove focus from the imput. Then generate the autocomplete.
    if (e.key === "Enter") e.target.blur();
  };

  useEffect(() => {
    onChangeDemands(getApertureDifference);
  }, [tags, totalAdjusted]);

  return (
    <header className={cx(css.cntHeaderDemand, "container-row")}>
      {userHasEditPermission && (
        <section className={cx(css.formDemand, "container-row")}>
          <p className={cx(css.formDemand_text, "col_12")}>Apertura por tipo de demanda</p>
          {tags.map(tag => {
            const labelText = tag.label.length >= 12 ? `${tag.label.slice(0, 8)}...` : tag.label;
            return (
              <Input
                key={tag.tagId}
                className={cx(css.cntInputs_item)}
                disabled={isLoading || disabled}
                label={labelText}
                maxLength={4}
                onBlur={() => !disabled && updateDemandDistribution()}
                onKeyDown={handleKeyDown}
                value={tag.value ? tag.value : 0}
                onChange={e => !disabled && handleInputTagChange(tag, e)}
              />
            );
          })}
        </section>
      )}
      <section className={cx(css.resumeDemand)}>
        <div className={cx(css.totalDeman, "container-row")}>
          <p className={cx(css.totalDeman_label, "col_3")}>Demanda total</p>
          <p className={cx(css.totalDeman_item, "col_2")}>Proyec. {totalProjected}</p>
          <p className={cx(css.totalDeman_item, "col_2")}>Ajust</p>
          <p className={cx(css.totalDeman_item)}>{totalAdjusted}</p>
        </div>
        {userHasEditPermission && (
          <div className={cx(css.totalDeman, "container-row")}>
            <p className={cx(css.totalDeman_label, "col_3")}>Apertura total</p>
            <p className={cx(css.totalDeman_item, "col_offset_left_2", "col_2")}>Apertura</p>
            <p className={cx(css.totalDeman_item, "col_2")}>{getTotalAperture}</p>
            {/* css.totalDeman_item__extra' -> className={cx(css.totalDeman_item, validacionExtra && css.totalDeman_item)} */}
            <p
              className={cx(
                css.totalDeman_item,
                !isLoading && getApertureDifference > 0 && css.totalDeman_item__overloaded,
              )}
            >
              {isLoading && `Cargando...`}
              {!isLoading && getApertureDifference > 0 && `+${getApertureDifference} sobrecargado`}
              {!isLoading && getApertureDifference < 0 && `${getApertureDifference} por distribuir`}
              {!isLoading && getApertureDifference === 0 && (
                <Icon className={cx(css.icon)} icon="check" />
              )}
            </p>
          </div>
        )}
      </section>
    </header>
  );
};

export default HeaderDemand;
