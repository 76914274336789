import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ForecastContextConsumer } from "../../../../context";
import { BreadCrumb } from "../../../../../../common";
import { FormCLEdit } from "./components";

const Container = (props: any) => {
  const breadCrumbItems = [
    { content: "Editor de demanda", link: true, to: props.routes.dashboard() },
    { content: "Editar lista cruzada", link: false },
  ];

  return (
    <div className="container-row">
      <div className="col">
        <BreadCrumb items={breadCrumbItems} />
        {props.content}
      </div>
    </div>
  );
};

class EditPageContainer extends Component<any, any> {
  render() {
    const unitID = this.props.match.params.unitId;
    return (
      <ForecastContextConsumer>
        {context =>
          context && (
            <Container
              routes={context.routes}
              content={
                <FormCLEdit
                  routes={context.routes}
                  unitId={unitID}
                  {...context}
                  projectId={context.projectId}
                />
              }
            />
          )
        }
      </ForecastContextConsumer>
    );
  }
}

export default withRouter(EditPageContainer);
