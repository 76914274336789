import React, { useState, useEffect, useContext } from "react";
import { Select } from "@foris/avocado-ui";
import { useQueryParam } from "../../../utils/utils";
import { IForecastContextInterface } from "../../../../forecast/context";
import { ContextApp } from "../../../../../context/AppContext";
import { Types, ICampusOptions } from "../../../../../context/dashboard.reducer";
import {
  filterByDepartment,
  filterByModality,
  filterByProgram,
  filterByShift,
  filterByCurriculum,
} from "../../../utils/filterByCampuses";
import css from "./selector.module.scss";

type IOption = {
  label: string;
  value: string;
};

interface ICampusesSelectorProps {
  error?: string;
  onCallback?: (setCampusReady: boolean) => void;
  project: any;
  context: IForecastContextInterface;
}

const CampusSelector: React.FC<ICampusesSelectorProps> = (props: ICampusesSelectorProps) => {
  const { error, project, onCallback } = props;
  const { state, dispatch } = useContext(ContextApp);
  const [firstLoad, setFirstLoad] = useState(true);
  const campusParam = useQueryParam()?.get("campus");
  const ALL_OPTION = { value: null, label: "Todos las sedes", id: "", name: "", code: "" };

  const onChange = (
    selectedCampus: IOption,
    campuses: ICampusOptions[] = state?.dashboard?.campusList,
  ) => {
    if (selectedCampus?.value) {
      dispatch({
        type: Types.SetCampus,
        payload: { options: campuses, value: selectedCampus },
      });
      dispatch({
        type: Types.SetDepartment,
        payload: {
          options: filterByDepartment(project.programs, selectedCampus, project.departments)?.map(
            value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            },
          ),
          value: null,
        },
      });
      dispatch({
        type: Types.SetModality,
        payload: {
          options: filterByModality(project.programs, selectedCampus, project.modalities)?.map(
            value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            },
          ),
          value: null,
        },
      });
      dispatch({
        type: Types.SetShift,
        payload: {
          options: filterByShift(project.programs, selectedCampus, project.shifts).map(value => {
            return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetProgram,
        payload: {
          options: filterByProgram(project.programs, selectedCampus)?.map(value => {
            return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetCurriculum,
        payload: {
          options: filterByCurriculum(project.programs, selectedCampus, project.curriculums).map(
            value => {
              return { ...value, label: `${value?.name}`, value: value?.id };
            },
          ),
          value: null,
        },
      });
      // callback
      onCallback && onCallback(true);
    } else {
      dispatch({
        type: Types.SetCampus,
        payload: { options: state?.dashboard?.campusList, value: null },
      });
      dispatch({
        type: Types.SetModality,
        payload: { options: [], value: null },
      });
      dispatch({
        type: Types.SetShift,
        payload: { options: [], value: null },
      });
      dispatch({
        type: Types.SetProgram,
        payload: { options: [], value: null },
      });
      dispatch({
        type: Types.SetCurriculum,
        payload: { options: [], value: null },
      });
    }
  };

  const onSearchFirstTime = () => {
    const { campusValue, campusList } = state?.dashboard;
    const campuses = project?.campuses?.map(campus => ({
      label: campus.code + " " + campus.name,
      value: campus.id,
    }));

    if (campusList === null) {
      dispatch({ type: Types.SetCampus, payload: { options: campuses, value: null } });
      setFirstLoad(true);
    }

    if (campusParam && campusParam !== campusValue?.value) {
      const findCampus = campuses?.find(value => value.value === campusParam);
      onChange(findCampus, campuses);
    }
  };

  useEffect(() => {
    if (firstLoad) {
      onSearchFirstTime();
      setFirstLoad(false);
    }
  });

  return (
    <Select
      className={css.selector}
      label="Sede"
      placeholder="Selecciona una opción"
      emptyOptionsText="Sin resultados"
      activeMaxHeight
      value={state?.dashboard?.campusValue || ALL_OPTION}
      error={error}
      isClearable
      options={state?.dashboard?.campusList}
      onChange={value => onChange(value, state?.dashboard?.campusList)}
    />
  );
};

export default CampusSelector;
