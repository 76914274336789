import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo";
import App from "./App";
import client from "./apolloClient";
import { ContextAppProvider } from "./context/AppContext";
import "semantic-ui-css/semantic.min.css";

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ContextAppProvider>
        <App />
      </ContextAppProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
