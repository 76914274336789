import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import logo from "../../../assets/svg/new_logo_darwined.svg";
import ContextScenario from "./contextScenario/ContextScenario";
import DropDownMenu from "./dropdownMenu/DropdownMenu";
import MenuHeader from "./menu/Menu";
import "../../../assets/scss/app.scss";
// import "../../../grid__css_poc/bootstrap-grid.min.css";
import "@foris/avocado-ui/lib/foris-avocado.css";
import cx from "classnames";
import css from "./layout.module.scss";

const Layout: React.FC<any> = (props: any) => {
  return (
    <article className={cx(css.layout, "container-row")}>
      <header className={cx(css.header, "container-row", "row--noWrap")}>
        {props.context && (
          <MenuHeader
            logoutUrl={props.context.auth.logoutUrl}
            context={props.context}
            routes={props.routes}
          />
        )}
        <Link to={props.routes && props.routes.dashboard()} className={css.cntImage}>
          <img
            src={logo}
            alt="Editor de Demandas"
            className={css.img}
            width="108.203px"
            height="28.668px"
          />
        </Link>
        <div className={css.cntInfo}>
          <p className={css.cntInfo_nameModule}>Editor de Demanda</p>
        </div>
        <ContextScenario {...props.context} />
        <div className={css.products}>
          <Menu.Menu position="left">
            <Menu.Item style={{ cursor: "pointer" }}>
              <DropDownMenu />
            </Menu.Item>
          </Menu.Menu>
        </div>
      </header>
      <section className={cx(css.content, "container-row")}>{props.children}</section>
    </article>
  );
};

export default Layout;
