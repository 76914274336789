/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import Autosuggest from "react-autosuggest";
import { Subject } from "rxjs";
import { Loader } from "semantic-ui-react";
import { GET_SUGGESTIONS_QUERY } from "../queries";
import styled from "styled-components";
import { useApolloClient } from "react-apollo";
import { debounce } from "lodash";
import "./search-field.scss";

const SearchDemandResult = styled.div`
  line-height: 1.4em;
  .departmentProgram {
    font-size: 0.9em;
  }
  .department {
    display: inline-block;
    font-size: 1em;
  }
  .program {
    display: inline-block;
    font-size: 1em;
  }
  .demand {
    font-size: 1em;
    font-weight: 500;
  }
  .courseName {
  }
  .curriculumName {
    border-radiu
  }
  .levelName {}
  .crossList {}
`;
interface ISearchFieldProps {
  projectId: string;
  excludeFromResults: any;
  client?: any;
  onSelectDemand: Function;
}

const DEBOUNCE_TIME = 1000;

const SearchField: React.FC<ISearchFieldProps> = (props: ISearchFieldProps) => {
  const { onSelectDemand, projectId, excludeFromResults } = props;
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionsCopyWrite, setSuggestionsCopyWrite] = useState([]);
  const onSearch$ = new Subject();
  const client = useApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (event: any, { newValue }: any) => setValue(newValue);

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionsFetchRequested = ({ value: newValue }: any) => {
    if (newValue === value) {
      setSuggestions(suggestionsCopyWrite);
    } else {
      onSearch$.next(value);
    }
  };

  const onSuggestionSelected = (event: any, { suggestion: newSuggestion }: any) => {
    if (newSuggestion && Object.keys(newSuggestion).length > 0) {
      onSelectDemand(newSuggestion);
    }
  };

  const inputProps = {
    placeholder: "Buscar...",
    value,
    onChange: onChange,
  };

  const renderSuggestion = (newSuggestion: any) => {
    if (!Object.keys(newSuggestion).length) return <span>No se han encontrado resultados.</span>;
    return (
      <SearchDemandResult>
        <div className="departmentProgram">
          <div className="program">{newSuggestion.course.curriculum.program.name}</div>
          {" | "}
          <div className="department">
            {newSuggestion.course.curriculum.program.department.name}
          </div>
        </div>
        <div className="demand">
          <span className="courseName">
            {newSuggestion.course.label}{" "}
            <span style={{ backgroundColor: "#e6e6e6", borderRadius: "3px", padding: "2px 4px" }}>
              {newSuggestion.course.code} {newSuggestion.course.name}
            </span>
          </span>
          {" | "}
          <span className="curriculumName">{newSuggestion.course.curriculum.name}</span>
          {" | "}
          <span className="levelName">Nivel: {newSuggestion.course.level}</span>
          {newSuggestion.relatedUnits &&
            newSuggestion.relatedUnits.crosslist &&
            newSuggestion.relatedUnits.crosslist.code && (
              <>
                {" | "}
                <span className="crossList">
                  Lista cruzada: {newSuggestion.relatedUnits.crosslist.code}
                </span>
              </>
            )}
        </div>
      </SearchDemandResult>
    );
  };

  const makeQueryAfterDevounceTime = async () => {
    if (value.length < 3) return;

    setIsLoading(true);

    let result: Array<any>;
    const {
      data: {
        project: { searchByDemand },
      },
    } = await client.query({
      query: GET_SUGGESTIONS_QUERY,
      variables: { projectId: projectId, q: value },
    });
    if (!searchByDemand.length) result = [{}];
    else result = searchByDemand;

    const excludes = Object.keys(excludeFromResults).map((key: string) => key);

    if (excludes.length) {
      result = result.filter((item: any) => {
        return !excludes.includes(item.id);
      });
    }
    setIsLoading(false);
    setSuggestions(result);
    setSuggestionsCopyWrite(result);
  };

  const delayedSearchQuery = useCallback(debounce(makeQueryAfterDevounceTime, DEBOUNCE_TIME), [
    value,
  ]);

  useEffect(() => {
    delayedSearchQuery();
    return delayedSearchQuery.cancel;
  }, [value]);

  return (
    <div className="field cntSearchField">
      <div className={isLoading ? "autosuggest__container isLoading" : "autosuggest__container"}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={() => value}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
        <Loader active inline size="mini" />
        <span className="helpBlock">Mínimo 3 caracteres para realizar la búsqueda.</span>
      </div>
    </div>
  );
};

export default SearchField;
