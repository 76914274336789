import gql from "graphql-tag";

export const filterDataQuery = gql`
  query filterDataQuery($projectId: ID!) {
    project(projectId: $projectId) {
      id
      campuses {
        id
        code
        name
      }
      departments {
        id
        code
        name
      }
      modalities {
        id
        code
        name
      }
      shifts {
        id
        code
        name
      }
      programs {
        id
        code
        name
        campus {
          id
        }
        department {
          id
        }
        modality {
          id
        }
        shift {
          id
        }
      }
      curriculums {
        id
        name
        code
        program {
          id
        }
      }
    }
    status {
      code
      msg
    }
  }
`;
