import React, { useState, useEffect, useContext } from "react";
import { Select } from "@foris/avocado-ui";
import { useQueryParam } from "../../../utils/utils";
import { ContextApp } from "../../../../../context/AppContext";
import { Types } from "../../../../../context/dashboard.reducer";
import { IForecastContextInterface } from "../../../../forecast/context";
import { filterByCurriculum } from "../../../utils/filterByProgram";
import css from "./selector.module.scss";

type IOption = {
  label: string;
  value: string;
};

interface IProgramsSelectorProps {
  error?: string;
  onCallback?: (setProgramReady: boolean) => void;
  project: any;
  context: IForecastContextInterface;
}

const ProgramsSelector: React.FC<IProgramsSelectorProps> = (props: IProgramsSelectorProps) => {
  const { error, onCallback, project } = props;
  const { state, dispatch } = useContext(ContextApp);
  const [firstLoad, setFirstLoad] = useState(true);
  const programParam = useQueryParam()?.get("program");
  const ALL_OPTION = {
    value: null,
    label: "Todas las carreras",
    id: "",
    name: "",
    code: "",
    campus: null,
    shift: null,
    modality: null,
    department: null,
  };

  const onChange = (program: IOption) => {
    dispatch({
      type: Types.SetProgram,
      payload: { options: state?.dashboard?.programList, value: program },
    });
    dispatch({
      type: Types.SetCurriculum,
      payload: {
        options: filterByCurriculum(
          project.programs,
          state?.dashboard?.departmentValue,
          project.curriculums,
          state?.dashboard?.modalityValue,
          state?.dashboard?.ShiftValue,
          program,
        ).map(value => {
          return { ...value, label: `${value?.name}`, value: value?.id };
        }),
        value: null,
      },
    });
    program && onCallback && onCallback(true);
  };

  const onSearchFirstTime = () => {
    const { programValue } = state?.dashboard;
    if ((programParam && !programValue?.value) || programParam !== programValue?.value) {
      const findProgram = state?.dashboard?.programList?.find(
        value => value.value === programParam,
      );
      onChange(findProgram);
    }
  };

  useEffect(() => {
    if (firstLoad && programParam && state?.dashboard?.programList?.length > 0) {
      onSearchFirstTime();
      setFirstLoad(false);
    }
  });

  const addAllOption = [...state?.dashboard?.programList];
  addAllOption.unshift(ALL_OPTION);

  return (
    <Select
      className={css.selector}
      value={state?.dashboard?.programValue || ALL_OPTION}
      activeMaxHeight
      emptyOptionsText="Sin resultados"
      error={error}
      isClearable
      label="Carrera"
      options={addAllOption}
      placeholder="Selecciona una opción"
      onChange={onChange}
      disabled={state?.dashboard?.campusValue ? false : true}
    />
  );
};

export default ProgramsSelector;
