import React from "react";
import { Query } from "react-apollo";
import { Loading } from "@foris/avocado-ui";
import { BreadCrumb } from "../../../../../../common";
import { DEFAULT_MESSAGES } from "../../../../../../common/config";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import ListPage from "./ListPage";
import { GET_UNITS_BY_KIND } from "./queries";

const Container = (props: any) => {
  const breadCrumbItems = [
    { content: "Editor de demanda", link: true, to: props.routes ? props.routes.dashboard() : "/" },
    { content: "Edición de listas cruzadas", link: false },
  ];

  return (
    <section className="main-section forecast">
      <div className="hero-body">
        <div className="container is-fluid">
          <BreadCrumb items={breadCrumbItems} />
          <div className="columns">
            <div className="column is-three-quarters">
              <div className="is-content has-background-white">
                <div className="content">{props.content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CLEditorContainer = ({ forecastContext }: { forecastContext?: IForecastContextInterface }) =>
  forecastContext && (
    <Query
      query={GET_UNITS_BY_KIND}
      variables={{ projectId: forecastContext.routes.context.project, kind: "CROSSLIST" }}
    >
      {({ loading, error, data }: any) => {
        if (loading) return <Container content={<Loading />} />;
        if (error || data.status.code !== 200)
          return (
            <Container routes={forecastContext.routes} content={<p>{DEFAULT_MESSAGES.ERROR}</p>} />
          );
        return (
          <Container
            routes={forecastContext.routes}
            content={<ListPage {...{ list: data.project.unitsByKind }} />}
          />
        );
      }}
    </Query>
  );

export default withForecastContext(CLEditorContainer);
