import gql from "graphql-tag";

const GET_UNIT_DETAIL = gql`
  query getUnitDetail($projectId: ID!, $unitId: ID!, $demandFilter: PaginationInput) {
    project(projectId: $projectId) {
      id
      unit(unitId: $unitId) {
        id
        kind
        label
        progress
        tags
        totalProjected
        totalAdjusted
        nextUnit
        previusUnit
        programName
        curriculumName
        description
        tags
        curriculum {
          id
          code
          program {
            id
            code
            name
          }
        }
        department {
          id
          name
        }
        crosslistConfirmation
        demandDistribution {
          tagId
          name
          label
          value
        }
        demandsPage(demandFilter: $demandFilter) {
          pageInfo {
            total
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
          items {
            id
            course {
              id
              name
              label
              level
              code
              isRule
              ruleCode {
                unitId
                code
              }
              curriculum {
                id
                name
                program {
                  id
                  name
                  code
                  campus {
                    id
                    code
                    name
                  }
                }
              }
            }
            user {
              id
              username
              email
            }
            value
            adjusted
            checked
            equivalenceGroup
            relatedUnits {
              crosslist {
                code
                id
              }
              level {
                code
                id
              }
              ruleCode {
                code
                id
              }
              subject {
                code
                id
              }
            }
            own
            courseLabel
            programName
            curriculumName
          }
        }
      }
    }
    status {
      code
      msg
    }
  }
`;

export default GET_UNIT_DETAIL;
