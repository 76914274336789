import gql from "graphql-tag";

export const getUnitsQuery = gql`
  query getUnitsQuery($projectId: ID!, $filter: ViewFilter) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
      }
      programs {
        id
        code
        name
        department {
          id
        }
      }
      curriculums {
        id
        name
        program {
          id
        }
      }
      dashboard {
        levelView(filter: $filter) {
          level
          curriculum {
            id
            name
            program {
              id
              name
              code
            }
          }
          unit {
            id
            kind
            label
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            campusName
            tags
            nextUnit
            previusUnit
          }
        }
        subjectView(filter: $filter) {
          subject
          unit {
            id
            kind
            label
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            campusName
            tags
            nextUnit
            previusUnit
            description
            access
          }
        }
        crosslistView(filter: $filter) {
          unit {
            id
            kind
            label
            description
            progress
            totalAdjusted
            totalDemand
            checkedCount
            programName
            curriculumName
            campusName
            crosslistConfirmation
            tags
            nextUnit
            previusUnit
          }
        }
      }
    }
    status {
      code
      msg
    }
  }
`;
