import React, { useState, useEffect, useContext } from "react";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Card, Button, Loading } from "@foris/avocado-ui";
import CampusSelector from "../../components/Header/Selectors/CampusSelector";
import DepartmentsSelector from "../../components/Header/Selectors/DepartmentsSelector";
import ModalitySelector from "../../components/Header/Selectors/ModalitySelector";
import ProgramsSelector from "../../components/Header/Selectors/ProgramsSelector";
import CurriculumsSelector from "../../components/Header/Selectors/CurriculumsSelector";
import ShiftSelector from "../../components/Header/Selectors/ShiftSelector";
import { ContextApp } from "../../../../context/AppContext";
import { getUnitsQuery } from "../../graphql/getUnitsQuery";
import { ViewFilter } from "../../../../models/ISchema";
import { useQueryParam } from "../../utils/utils";
import { ForecastContextConsumer } from "../../../forecast/context";
import css from "./header.module.scss";

const Header = (props: any) => {
  const { project } = props;
  const client = useApolloClient();
  const { state } = useContext(ContextApp);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(true);
  const campusParam = useQueryParam()?.get("campus");
  const departmentParam = useQueryParam()?.get("department");
  const modalityParam = useQueryParam()?.get("modality");
  const shiftParam = useQueryParam()?.get("shift");
  const curriculumParam = useQueryParam()?.get("curriculum");
  const programParam = useQueryParam()?.get("program");

  const searchUnits = async () => {
    const {
      campusValue,
      departmentValue,
      programValue,
      curriculumValue,
      modalityValue,
      ShiftValue,
    } = state?.dashboard;

    if (!campusValue?.value) return false;

    const filters: ViewFilter = {
      campusId: campusValue?.value,
      departmentId: departmentValue?.value,
      programId: programValue?.value,
      curriculumId: curriculumValue?.value,
      modalityId: modalityValue?.value,
      shiftId: ShiftValue?.value,
    };

    const result = await client.query({
      query: getUnitsQuery,
      variables: {
        projectId: props.context.routes.context.project,
        filter: filters,
      },
    });

    return result.data.project.dashboard;
  };

  const onSearch = async () => {
    setLoading(true);
    const result = await searchUnits();
    props.context.updateDashboardCurrentData("unitView", result);
    props.context.updateDashboardCurrentData("filterSearchState", "TOUCHED");
    setLoading(false);
  };

  useEffect(() => {
    const {
      campusValue,
      departmentValue,
      modalityValue,
      ShiftValue,
      curriculumValue,
      programValue,
    } = state?.dashboard;

    let countParam = 0;
    if (campusParam) countParam++;
    if (departmentParam) countParam++;
    if (modalityParam) countParam++;
    if (shiftParam) countParam++;
    if (curriculumParam) countParam++;
    if (programParam) countParam++;

    let stateParam = 0;
    if (campusValue?.value) stateParam++;
    if (departmentValue?.value) stateParam++;
    if (modalityValue?.value) stateParam++;
    if (ShiftValue?.value) stateParam++;
    if (curriculumValue?.value) stateParam++;
    if (programValue?.value) stateParam++;

    if (countParam > 0 && countParam === stateParam && search) {
      onSearch();
      setSearch(false);
    }
  });

  return (
    <ForecastContextConsumer>
      {context => {
        if (!context) return <p>error...</p>;
        return (
          <>
            {loading && <Loading />}
            <Card.Simple className={cx(css.dashboardHeader, "container-row")}>
              <Card.Content className={cx(css.cardContent)}>
                <div className={cx(css.cardContent_selectors)}>
                  <CampusSelector project={project} context={context} />
                  <DepartmentsSelector project={project} context={context} />
                  <ModalitySelector project={project} context={context} />
                  <ShiftSelector project={project} context={context} />
                  <ProgramsSelector project={project} context={context} />
                  <CurriculumsSelector project={project} context={context} />
                </div>
                <Button
                  disabled={!state?.dashboard?.campusValue}
                  onClick={() => {
                    onSearch();
                  }}
                >
                  Buscar
                </Button>
              </Card.Content>
            </Card.Simple>
          </>
        );
      }}
    </ForecastContextConsumer>
  );
};

export default Header;
