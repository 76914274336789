import React from "react";
import { useQuery } from "react-apollo";
import { GET_SCENARIOS } from "./context.queries";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { Scenario } from "../../../../../models/ISchema";

interface ISelectProps {
  workspaceValue: any;
  scenarioValue: any;
  defaultScenarioId: string;
  onChange: Function;
}

const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

const ScenariosSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
  const { workspaceValue, onChange, scenarioValue, defaultScenarioId } = props;
  const { loading, error, data } = useQuery<any, any>(GET_SCENARIOS, {
    variables: {
      workspaceId: workspaceValue ? workspaceValue.value : "" || "", // TODO: change default "2" by the global context
    },
    fetchPolicy: "no-cache",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error)
    return (
      <div style={{ padding: "10px 0" }}>
        <Select
          options={[]}
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#e5e8ec",
              primary: "#6993c9",
              neutral80: "#575d62",
              neutral90: "#575d62",
            },
          })}
          placeholder="No hay datos..."
        />
      </div>
    );
  const scenarios: Array<Scenario> = data.scenarios;
  const options = scenarios.map((scenario: Scenario) => {
    return { label: scenario.name, value: scenario.id };
  });

  const selectedValue = options.find(option => {
    if (scenarioValue && scenarioValue.value) return scenarioValue.value === option.value;
    if (defaultScenarioId) return defaultScenarioId === option.value;
  });

  if ((!scenarioValue || !scenarioValue.value) && selectedValue) onChange(selectedValue);

  return (
    <div style={{ padding: "10px 0" }}>
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={selectedValue}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};
export default ScenariosSelect;
