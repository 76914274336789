import { Program, Shift, Curriculum } from "../../../models/ISchema";

type IOption = {
  label: string;
  value: string;
};

export const filterByShift = (
  programs: Program[],
  department: IOption,
  shift: Shift[],
  modality: IOption,
): Shift[] => {
  const selectablePrograms = programs.filter(item =>
    department
      ? item.department.id === department.value && item?.modality?.id === modality.value
      : false,
  );
  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.shift.id);
  const selectableShifts = shift.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.id);
  });

  return selectableShifts;
};

export const filterByProgram = (
  programs: Program[],
  program: IOption,
  modality: IOption,
): Program[] => {
  //Filter programs by departments
  const selectablePrograms = programs.filter(item =>
    program ? item.department.id === program.value && item?.modality?.id === modality.value : false,
  );

  return selectablePrograms;
};

export const filterByCurriculum = (
  programs: Program[],
  department: IOption,
  curriculums: Curriculum[],
  modality: IOption,
): Curriculum[] => {
  // Filter curriculums by program
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    department
      ? item.department.id === department.value && item?.modality?.id === modality.value
      : false,
  );
  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.id);

  const selectableCurriculums = curriculums.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.program.id);
  });

  return selectableCurriculums;
};
