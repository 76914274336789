import React from "react";
import { useQuery } from "react-apollo";
import { Loading } from "@foris/avocado-ui";
import { DEFAULT_MESSAGES } from "../../../common/config";
import { withForecastContext, IForecastContextInterface } from "../../forecast/context";
import { filterDataQuery } from "../graphql/filterDataQuery";
import Layout from "../components/Layout/Layout";
import Header from "./Header/Header";
import Units from "./Units/Units";

interface IDashboardProps {
  forecastContext?: IForecastContextInterface;
}

const Dashboard = (props: IDashboardProps) => {
  const { forecastContext } = props;
  const { data, loading, error } = useQuery(filterDataQuery, {
    variables: { projectId: forecastContext.routes.context.project },
  });

  if (loading)
    return (
      <Layout context={forecastContext}>
        <Loading />
      </Layout>
    );

  if (error || data.status.code !== 200)
    return (
      <Layout context={forecastContext}>
        <h5>{DEFAULT_MESSAGES.CONTEXT_ERROR}</h5>
      </Layout>
    );

  if (data.project) {
    return (
      <Layout context={forecastContext}>
        <>
          <Header
            {...data}
            context={forecastContext}
            projectId={forecastContext.routes.context.project}
          />
          <Units {...forecastContext} projectId={forecastContext.routes.context.project} />
        </>
      </Layout>
    );
  } else {
    return (
      <Layout context={forecastContext}>
        <p>{DEFAULT_MESSAGES.ERROR}</p>
      </Layout>
    );
  }
};

export default withForecastContext(Dashboard);
