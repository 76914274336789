import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const NussWrapper = styled.div`
  margin: 0 auto;
  width: 150px;
  height: 150px;
  padding: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    max-width: 50%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-size: 4em;
    color: #757373;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  -webkit-animation: spin 6s linear infinite;
  -moz-animation: spin 6s linear infinite;
  animation: spin 6s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
      -moz-transform-origin: center center;
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      -webkit-transform-origin: center center;
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      transform-origin: center center;
      -webkit-transform-origin: center center;
    }
  }
`;

const Message = styled.p`
  font-size: 1.8em;
  font-weight: 300;
  max-width: 56%;
  margin: 0 auto;
`;

const ProjectStatus: React.FC = () => {
  return (
    <div className="container-row">
      <div className="col">
        <div style={{ backgroundColor: "white", padding: "3em 3em 6em 3em", textAlign: "center" }}>
          <NussWrapper>
            <Icon name="setting" />
          </NussWrapper>
          <Message>
            Se está actualizando la información del proyecto. <br />
            El proceso tomará algunos minutos.
          </Message>
        </div>
      </div>
    </div>
  );
};

export default ProjectStatus;
