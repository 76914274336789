import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import {
  ISubject,
  IFilterSelected,
} from "../../../../forecast/modules/demand-editor/pages/dashboard/models";
import ProgressBar from "../../../../../common/components/ProgressBar/ProgressBar";
import { useGenerateStringFilter } from "../../../utils/utils";
import css from "./subjectListView.module.scss";

interface ISubjectListItemProps {
  unit: ISubject;
  routes?: any;
  filterSelected?: IFilterSelected;
}

const SubjectListItem: React.FunctionComponent<ISubjectListItemProps> = (
  props: ISubjectListItemProps,
) => {
  const { unit, routes, filterSelected } = props;
  const { campus, department, program, curriculum, modality, shift } = filterSelected;
  const stringFilter = useGenerateStringFilter(
    campus,
    department,
    program,
    curriculum,
    modality,
    shift,
    "1",
  );
  const history = useHistory();

  const handleClick = () => {
    history.push(`${routes.unit(unit.unit.id)}${stringFilter}`);
  };

  const acccess = unit.unit.access?.join(", ");
  const campuses = unit.unit.campusName?.join(", ");

  return (
    <ul className={cx(css.cntRowList, "container-row")} onClick={handleClick}>
      <li className={cx(css.cntRowList_item, css.cntRowList_item__progressBar)}>
        <ProgressBar progress={unit.unit.progress} />
      </li>
      <li className={cx(css.cntRowList_item, "col_1")}>{campuses}</li>
      <li className={cx(css.cntRowList_item, "col_3")}>{unit.unit.label}</li>
      <li className={cx(css.cntRowList_item, "col_4")}>{unit.unit.description}</li>
      <li className={cx(css.cntRowList_item, "col_2")}>{acccess}</li>
    </ul>
  );
};

export default SubjectListItem;
