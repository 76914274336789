import React from "react";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import { BreadCrumb } from "../../../../../../common";
import { FormCLAdd } from "./components";

const Container = (props: any) => {
  const breadCrumbItems = [
    { content: "Editor de demanda", link: true, to: props.routes ? props.routes.dashboard() : "/" },
    { content: "Crear lista cruzada", link: false },
  ];

  return (
    <section className="container-row">
      <BreadCrumb items={breadCrumbItems} />
      <section className="container-row">{props.content}</section>
    </section>
  );
};

const AddPageContainer = ({ forecastContext }: { forecastContext?: IForecastContextInterface }) =>
  forecastContext && (
    <Container
      routes={forecastContext.routes}
      content={
        <FormCLAdd
          routes={forecastContext.routes}
          {...forecastContext}
          projectId={forecastContext.projectId}
        />
      }
    />
  );

export default withForecastContext(AddPageContainer);
