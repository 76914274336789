import React from "react";
import { Popup } from "semantic-ui-react";
import cx from "classnames";
import catalogos from "./../../../../assets/menuForisIcons/catalogos.svg";
import proyecciones from "./../../../../assets/menuForisIcons/proyecciones.svg";
import asignaciones from "./../../../../assets/menuForisIcons/asignaciones.svg";
import horarios from "./../../../../assets/menuForisIcons/analytics.svg";
import edd from "./../../../../assets/menuForisIcons/edd.svg";
import edh from "./../../../../assets/menuForisIcons/edh.svg";
import espacios from "./../../../../assets/menuForisIcons/espacios.svg";
// import analytics from "./../../../../assets/menuForisIcons/analytics.svg";
import css from "./dropdown-menu.module.scss";
import Icon from "../../Icon/Icon";

interface IModules {
  title: string;
  icon: any;
  order: number;
  accesible: boolean;
  url: string;
  code: string;
  urlProduct: string;
}

const DropDownMenu = () => {
  const currentStorage = JSON.parse(localStorage.__darwined_user_data);
  const icons = {
    catalogs: {
      icon: catalogos,
      title: "Catálogos",
      order: 1,
    },
    forecast: {
      icon: proyecciones,
      title: "Proyecciones",
      order: 2,
    },
    assignation: {
      icon: asignaciones,
      title: "Asignaciones",
      order: 3,
    },
    schedule: {
      icon: horarios,
      title: "Horarios",
      order: 4,
    },
    scheduler_editor: {
      icon: edh,
      title: "Editor de horarios",
      order: 2,
    },
    demand_editor: {
      icon: edd,
      title: "Editor de demanda",
      order: 1,
    },
    bookings: {
      icon: espacios,
      title: "Reserva de espacios",
      order: 3,
    },
  };

  const firstModules: IModules[] = [];
  const secondModules: IModules[] = [];
  if (currentStorage.modules) {
    currentStorage.modules.forEach((value, index) => {
      const findModule = icons[value.code];
      if (findModule && index <= 3) {
        firstModules.push({
          ...value,
          title: findModule.title,
          icon: findModule.icon,
          order: findModule.order,
        });
      }
      if (findModule && index > 3) {
        secondModules.push({
          ...value,
          title: findModule.title,
          icon: findModule.icon,
          order: findModule.order,
        });
      }
    });
  }

  const orderFirst =
    firstModules.length > 0 ? firstModules.sort((a, b) => a.order - b.order) : null;
  const orderSecond =
    secondModules.length > 0 ? secondModules.sort((a, b) => a.order - b.order) : null;

  const token = currentStorage.token;

  if (orderFirst || secondModules)
    return (
      <Popup
        className={cx("right", css.menuProducts)}
        trigger={
          <div>
            <Icon icon="cube" className={css.menuProducts_icon} />
          </div>
        }
        flowing
        on="click"
        co
      >
        {orderFirst.length > 0 && (
          <section className={cx(css.cntProducts, "container-row")}>
            <p className={cx(css.cntProducts_title, "col_12")}>Módulos de optimización</p>
            {orderFirst.map((value, index) => {
              if (value.accesible)
                return (
                  <figure
                    key={index}
                    onClick={() => (window.location.href = value.url)}
                    className={cx(css.cntProducts_item)}
                  >
                    <img className={cx(css.logo)} src={value.icon} />
                    <p className={cx(css.nameProduct, "col_12")}>{value.title}</p>
                  </figure>
                );
              return null;
            })}
          </section>
        )}
        {orderSecond.length > 0 && (
          <section className={cx(css.cntProducts, "container-row")}>
            <p className={cx(css.cntProducts_title, "col_12")}>Módulos de gestión</p>
            {secondModules.map((value, index) => {
              if (value.accesible && value.url) {
                let urlProduct = value.url;
                if (value.code == "demand_editor" && token) urlProduct = "/";
                if (value.code == "scheduler_editor" && token)
                  urlProduct = `${value.url}auth/${token}`;
                if (value.code == "bookings" && token)
                  urlProduct = `${value.url}auth?token=${token}`;
                return (
                  <figure
                    key={index}
                    onClick={() => (window.location.href = urlProduct)}
                    className={cx(css.cntProducts_item)}
                  >
                    <img className={cx(css.logo)} src={value.icon} />
                    <p className={cx(css.nameProduct, "col_12")}>{value.title}</p>
                  </figure>
                );
              }
              return null;
            })}
          </section>
        )}
      </Popup>
    );

  return null;
};

export default DropDownMenu;
