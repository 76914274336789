import React, { createContext, useReducer, Dispatch } from "react";
import { ReactNode } from "react";
import {
  dashboardReducer,
  DashboardActions,
  DashboardTypes,
  SelectDashboard,
} from "./dashboard.reducer";

export type InitialStateType = {
  dashboard?: DashboardTypes;
};

export const initialState: InitialStateType = {
  dashboard: {
    campusList: null,
    campusValue: null,
    departmentList: null,
    departmentValue: null,
    modalityList: [],
    modalityValue: null,
    shiftList: [],
    ShiftValue: null,
    curriculumList: [],
    curriculumValue: null,
    programList: [],
    programValue: null,
  },
};

const ContextApp = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<DashboardActions | SelectDashboard>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ dashboard }: InitialStateType, action: any) => ({
  dashboard: dashboardReducer(dashboard, action),
});

const ContextAppProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<any>(mainReducer, initialState);

  return <ContextApp.Provider value={{ state, dispatch }}>{children}</ContextApp.Provider>;
};

export { ContextAppProvider, ContextApp };
