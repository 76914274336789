const APP_NAME = process.env.REACT_APP_NAME;
const ENV = process.env.REACT_APP_ENVIRONMENT;
const DEBUG = Boolean(process.env.REACT_APP_DEBUG);
const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;
const GRAPHQL_WS_URI = process.env.REACT_APP_GRAPHQLSW_URI;
const DARWINED_URL = process.env.REACT_APP_DARWINED_URL;

const DEFAULT_MESSAGES = {
  ERROR: "Ha ocurrido un error. Inténtalo nuevamente",
  CONTEXT_ERROR:
    "Debes seleccionar un proyecto para este Escenario. Si no hay proyectos disponibles, por favor ponte en contacto con tu administrador DarwinEd.",
};

export { APP_NAME, ENV, DEBUG, GRAPHQL_URI, GRAPHQL_WS_URI, DEFAULT_MESSAGES, DARWINED_URL };
