import gql from "graphql-tag";

const EDIT_DEMAND = gql`
  mutation editDemand($unitId: ID!, $demandAdjustments: [DemandAdjustment]) {
    editDemand(unitId: $unitId, demandAdjustments: $demandAdjustments) {
      id
      kind
      progress
      totalAdjusted
    }
  }
`;

export default EDIT_DEMAND;
