import Routes, { IRouteType, IContext } from "./Routes";
import ProjectStatus from "../modules/forecast/modules/demand-editor/pages/project-status";
import EditPageContainer from "../modules/forecast/modules/demand-editor/pages/crosslist-editor/EditPageContainer";
import AddPageContainer from "../modules/forecast/modules/demand-editor/pages/crosslist-editor/AddPageContainer";
import CLEditor from "../modules/forecast/modules/demand-editor/pages/crosslist-editor/CLEditorContainer";
import AddProjectPage from "../modules/forecast/modules/demand-editor/pages/project/AddProjectPageContainer";
import EditProjectPage from "../modules/forecast/modules/demand-editor/pages/project/EditProjectPageContainer";
import ProjectList from "../modules/forecast/modules/demand-editor/pages/project/ProjectListContainer";
// import Editor from "../modules/forecast/modules/demand-editor/pages/editor/EditorContainer";
import Editor from "../modules/forecast/modules/demand-editor/pages/editor/Editor";
import Dashboard from "../modules/dashboard/pages/Dashboard";
export default class Forecast extends Routes {
  constructor(context: IContext) {
    super(context, "/forecast", "forecast@demand-editor");
  }

  // ProjectStatus
  routeProjectStatus = (): IRouteType => this.routeType("project-status", ProjectStatus, "public");

  projectStatus = (): string => `${this.base}/project-status/`;

  // CrosslistEdit
  routeCrosslistEdit = (): IRouteType =>
    this.routeTypeWithContext("crosslistedit", EditPageContainer, "onlyAdmin", "/:unitId");

  crosslistEdit = (unitId: string): string =>
    `${this.base}/crosslistedit/${this.getStringContext()}/${unitId}`;

  // CrosslistAdd
  routeCrosslistAdd = (): IRouteType =>
    this.routeTypeWithContext("crosslistadd", AddPageContainer, "onlyAdmin");

  crosslistAdd = (): string => `${this.base}/crosslistadd/${this.getStringContext()}`;

  // CrossList
  routeCrossList = (): IRouteType => this.routeTypeWithContext("crosslist", CLEditor, "onlyAdmin");

  crossList = (): string => `${this.base}/crosslist/${this.getStringContext()}`;

  // ProjectAdd
  routeProjectAdd = (): IRouteType =>
    this.routeTypeWithContext("projectadd", AddProjectPage, "onlyAdmin");

  projectAdd = (): string => `${this.base}/projectadd/${this.getStringContext()}`;

  // ProjectEdit
  routeProjectEdit = (): IRouteType =>
    this.routeTypeWithContext("projectedit", EditProjectPage, "onlyAdmin", "/:projectId");

  projectEdit = (projectId: string): string =>
    `${this.base}/projectedit/${this.getStringContext()}/${projectId}`;

  // ProjectList
  routeProjectList = (): IRouteType =>
    this.routeTypeWithContext("projects", ProjectList, "onlyAdmin");

  projectList = (): string => `${this.base}/projects/${this.getStringContext()}`;

  // Unit
  routeUnit = (): IRouteType => this.routeTypeWithContext("unit", Editor, "public", "/:unitId");

  unit = (unitId: string): string => `${this.base}/unit/${this.getStringContext()}/${unitId}`;

  // Dashboard
  routeDashboard = (): IRouteType => this.routeTypeWithContext("dashboard", Dashboard, "public");

  dashboard = (): string => `${this.base}/dashboard/${this.getStringContext()}`;
}
