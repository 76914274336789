import React, { useState, useEffect, useContext } from "react";
import { Select } from "@foris/avocado-ui";
import { useQueryParam } from "../../../utils/utils";
import { ContextApp } from "../../../../../context/AppContext";
import { Types } from "../../../../../context/dashboard.reducer";
import { IForecastContextInterface } from "../../../../forecast/context";
import {
  filterByShift,
  filterByProgram,
  filterByCurriculum,
} from "../../../utils/filterByModality";
import * as filterByDepartments from "../../../utils/filterByDepartments";
import css from "./selector.module.scss";

type IOption = {
  label: string;
  value: string;
};

interface IModalitySelectorProps {
  error?: string;
  onCallback?: (setModalityReady: boolean) => void;
  project: any;
  context: IForecastContextInterface;
}

const ModalitySelector: React.FC<IModalitySelectorProps> = (props: IModalitySelectorProps) => {
  const { error, onCallback, project } = props;
  const { state, dispatch } = useContext(ContextApp);
  const [firstLoad, setFirstLoad] = useState(true);
  const modalityParam = useQueryParam()?.get("modality");
  const ALL_OPTION = { value: null, label: "Todas las modalidades", id: "", name: "", code: "" };

  const onChange = (modality: IOption) => {
    if (modality?.value) {
      dispatch({
        type: Types.SetShift,
        payload: {
          options: filterByShift(
            project.programs,
            state?.dashboard?.departmentValue,
            project.shifts,
            modality,
          ).map(value => {
            return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetProgram,
        payload: {
          options: filterByProgram(
            project.programs,
            state?.dashboard?.departmentValue,
            modality,
          )?.map(value => {
            return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetCurriculum,
        payload: {
          options: filterByCurriculum(
            project.programs,
            state?.dashboard?.departmentValue,
            project.curriculums,
            modality,
          ).map(value => {
            return { ...value, label: `${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetModality,
        payload: { options: state?.dashboard?.modalityList, value: modality },
      });
    } else {
      dispatch({
        type: Types.SetModality,
        payload: { options: state?.dashboard?.modalityList, value: ALL_OPTION },
      });
      dispatch({
        type: Types.SetShift,
        payload: {
          options: filterByDepartments
            .filterByShift(project.programs, state?.dashboard?.departmentValue, project.shifts)
            .map(value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetProgram,
        payload: {
          options: filterByDepartments
            .filterByProgram(project.programs, state?.dashboard?.departmentValue)
            .map(value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetCurriculum,
        payload: {
          options: filterByDepartments
            .filterByCurriculum(
              project.programs,
              state?.dashboard?.departmentValue,
              project.curriculums,
            )
            .map(value => {
              return { ...value, label: `${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
    }
    onCallback && onCallback(true);
  };

  const onSearchFirstTime = () => {
    const { modalityValue } = state?.dashboard;
    if ((modalityParam && !modalityValue?.value) || modalityParam !== modalityValue?.value) {
      const findModality = state?.dashboard?.modalityList?.find(
        value => value.value === modalityParam,
      );
      onChange(findModality);
    }
  };

  useEffect(() => {
    if (firstLoad && modalityParam && state?.dashboard?.modalityList.length > 0) {
      onSearchFirstTime();
      setFirstLoad(false);
    }
  });

  const addAllOption = [...state?.dashboard?.modalityList];
  addAllOption.unshift(ALL_OPTION);

  return (
    <Select
      className={css.selector}
      label="Modalidad"
      placeholder="Selecciona una opción"
      emptyOptionsText="Sin resultados"
      activeMaxHeight
      value={state?.dashboard?.modalityValue || ALL_OPTION}
      error={error}
      options={addAllOption}
      onChange={onChange}
      disabled={state?.dashboard?.campusValue ? false : true}
    />
  );
};

export default ModalitySelector;
