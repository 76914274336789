import { Loading } from "@foris/avocado-ui";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import fakeAuth from "../../authClient";
import { DARWINED_URL } from "../../common/config";

const Login: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    fakeAuth.signOut((response: boolean) => {
      response && console.log("ok ~");
    });
    if (fakeAuth.isAuthenticated()) history.push("/");
    fakeAuth.signIn((isValid: boolean) => {
      if (isValid) history.push("/");
      else window.location.href = DARWINED_URL;
    });
  });

  return <Loading />;
};

export default Login;
