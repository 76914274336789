import { Program, Modality, Shift, Curriculum } from "../../../models/ISchema";

type IOption = {
  label: string;
  value: string;
};

export const filterByModality = (
  programs: Program[],
  department: IOption,
  modalities: Modality[],
): Modality[] => {
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    department ? item.department.id === department.value : false,
  );
  // Filter result programs by modality
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(
    program => program.modality.id,
  );
  // result options (modality)
  const selectableModalities = modalities.filter((modality: any) => {
    return modalitiesIdsFromSelectablePrograms.includes(modality.id);
  });

  return selectableModalities;
};

export const filterByProgram = (programs: Program[], program: IOption): Program[] => {
  //Filter programs by departments
  const selectablePrograms = programs.filter(item =>
    program ? item.department.id === program.value : false,
  );

  return selectablePrograms;
};

export const filterByShift = (
  programs: Program[],
  department: IOption,
  shift: Shift[],
): Shift[] => {
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    department ? item.department.id === department.value : false,
  );
  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.shift.id);
  const selectableShifts = shift.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.id);
  });

  return selectableShifts;
};

export const filterByCurriculum = (
  programs: Program[],
  department: IOption,
  curriculums: Curriculum[],
): Curriculum[] => {
  // Filter curriculums by program
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    department ? item.department.id === department.value : false,
  );
  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.id);

  const selectableCurriculums = curriculums.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.program.id);
  });

  return selectableCurriculums;
};
