import gql from "graphql-tag";

export const GET_FILTERS_DATA = gql`
  query GET_FILTERS_DATA($projectId: ID!) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
      }
      modalities {
        id
        code
        name
      }
      shifts {
        id
        code
        name
      }
      programs {
        id
        code
        name
        department {
          id
        }
        modality {
          id
        }
        shift {
          id
        }
      }
      curriculums {
        id
        name
        code
        program {
          id
        }
      }
    }
    status {
      code
      msg
    }
  }
`;

export const GET_CAMPUSES = gql`
  query GET_CAMPUSES($projectId: ID!) {
    project(projectId: $projectId) {
      id
      campuses {
        id
        code
        name
      }
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query GET_DEPARTMENTS($projectId: ID!) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
        campus {
          id
          name
        }
      }
    }
  }
`;

export const GET_UNITS = gql`
  query GET_UNITS($projectId: ID!, $filter: ViewFilter) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
      }
      programs {
        id
        code
        name
        department {
          id
        }
      }
      curriculums {
        id
        name
        program {
          id
        }
      }
      dashboard {
        levelView(filter: $filter) {
          level
          curriculum {
            id
            name
            program {
              id
              name
              code
            }
          }
          unit {
            id
            kind
            label
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            tags
            nextUnit
            previusUnit
          }
        }
        subjectView(filter: $filter) {
          subject
          unit {
            id
            kind
            label
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            tags
            nextUnit
            previusUnit
            description
            access
          }
        }
        crosslistView(filter: $filter) {
          unit {
            id
            kind
            label
            description
            progress
            totalAdjusted
            totalDemand
            checkedCount
            programName
            curriculumName
            crosslistConfirmation
            tags
            nextUnit
            previusUnit
          }
        }
      }
    }
    status {
      code
      msg
    }
  }
`;

export const UNITS_SUBSCRIPTION = gql`
  subscription onUnitsUpdate($projectId: ID!) {
    unitUpdated(projectId: $projectId) {
      id
      kind
      label
      progress
      totalDemand
      checkedCount
      programName
      curriculumName
      tags
      nextUnit
      previusUnit
      demands {
        id
        course {
          id
          code
          name
        }
        value
        adjusted
        checked
        own
        courseCode
        courseLabel
        programName
        curriculumName
      }
    }
  }
`;

// --- NO SE USAN ----

export const GET_CURRICULUMS = gql`
  query GET_CURRICULUMS($projectId: ID!, $curriculumFilter: CurriculumFilter) {
    project(projectId: $projectId) {
      id
      curriculums(filter: $curriculumFilter) {
        id
        name
      }
    }
  }
`;

export const UPDATE_DEPARTMENT_FILTER_CONTROL = gql`
  mutation UpdateDepartmentFilterControl($filterValue: FilterControl!) {
    updateDepartmentFilterControl(filterValue: $filterValue) @client
  }
`;

export const UPDATE_PROGRAM_FILTER_CONTROL = gql`
  mutation UpdateProgramFilterControl($filterValue: FilterControl!) {
    updateProgramFilterControl(filterValue: $filterValue) @client
  }
`;

export const UPDATE_CURRENT_FILTERS = gql`
  mutation UpdateCurrentFilters($departmentId: String, $programId: String) {
    updateCurrentFilters(departmentId: $departmentId, programId: $programId) @client
  }
`;

export const FILTER_CONTROL_QUERY = gql`
  query FiltersControlQuery {
    departmentFilterControl @client {
      value
      label
      filterName
    }
    programFilterControl @client {
      value
      label
      filterName
    }
    currentFilters @client {
      departmentId
      programId
    }
  }
`;
// ----------------
