import { Program, Modality, Shift, Curriculum, Department } from "../../../models/ISchema";

type IOption = {
  label: string;
  value: string;
};

export const filterByDepartment = (
  programs: Program[],
  campus: IOption,
  departments: Department[],
): Department[] => {
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    campus ? item.campus.id === campus.value : false,
  );
  // Filter result programs by modality
  const departmentIdsFromSelectablePrograms = selectablePrograms.map(
    program => program.department.id,
  );
  // result options (modality)
  const selectableDepartments = departments.filter((department: any) => {
    return departmentIdsFromSelectablePrograms.includes(department.id);
  });

  return selectableDepartments;
};

export const filterByModality = (
  programs: Program[],
  campus: IOption,
  modalities: Modality[],
): Modality[] => {
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    campus ? item.campus.id === campus.value : false,
  );
  // Filter result programs by modality
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(
    program => program.modality.id,
  );
  // result options (modality)
  const selectableModalities = modalities.filter((modality: any) => {
    return modalitiesIdsFromSelectablePrograms.includes(modality.id);
  });

  return selectableModalities;
};

export const filterByProgram = (programs: Program[], program: IOption): Program[] => {
  //Filter programs by departments
  const selectablePrograms = programs.filter(item =>
    program ? item.campus.id === program.value : false,
  );

  return selectablePrograms;
};

export const filterByShift = (programs: Program[], campus: IOption, shift: Shift[]): Shift[] => {
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    campus ? item.campus.id === campus.value : false,
  );
  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.shift.id);
  const selectableShifts = shift.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.id);
  });

  return selectableShifts;
};

export const filterByCurriculum = (
  programs: Program[],
  campus: IOption,
  curriculums: Curriculum[],
): Curriculum[] => {
  // Filter curriculums by program
  // Filter programs by department
  const selectablePrograms = programs.filter(item =>
    campus ? item.campus.id === campus.value : false,
  );
  // Filter result programs by shift
  const modalitiesIdsFromSelectablePrograms = selectablePrograms.map(program => program.id);

  const selectableCurriculums = curriculums.filter(item => {
    return modalitiesIdsFromSelectablePrograms.includes(item.program.id);
  });

  return selectableCurriculums;
};
