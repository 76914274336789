import React, { useState, useEffect, useContext } from "react";
import { Select } from "@foris/avocado-ui";
import { useQueryParam } from "../../../utils/utils";
import { IForecastContextInterface } from "../../../../forecast/context";
import { ContextApp } from "../../../../../context/AppContext";
import { Types, IDepartmentOptions } from "../../../../../context/dashboard.reducer";
import {
  filterByModality,
  filterByProgram,
  filterByShift,
  filterByCurriculum,
} from "../../../utils/filterByDepartments";
import * as filterByCampuses from "../../../utils/filterByCampuses";
import css from "./selector.module.scss";

type IOption = {
  label: string;
  value: string;
};

interface IDepartmentsSelectorProps {
  error?: string;
  onCallback?: (setDepartmentReady: boolean) => void;
  project: any;
  context: IForecastContextInterface;
}

const DepartmentsSelector: React.FC<IDepartmentsSelectorProps> = (
  props: IDepartmentsSelectorProps,
) => {
  const { error, project, onCallback } = props;
  const { state, dispatch } = useContext(ContextApp);
  const [firstLoad, setFirstLoad] = useState(true);
  const departmentParam = useQueryParam()?.get("department");
  const ALL_OPTION = {
    value: null,
    label: "Todas las escuelas",
    id: "",
    name: "",
    code: "",
    campus: null,
  };

  const onChange = (
    selectedDepartment: IOption,
    departments: IDepartmentOptions[] = state?.dashboard?.departmentList,
  ) => {
    if (selectedDepartment?.value) {
      dispatch({
        type: Types.SetModality,
        payload: {
          options: filterByModality(project.programs, selectedDepartment, project.modalities)?.map(
            value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            },
          ),
          value: null,
        },
      });
      dispatch({
        type: Types.SetShift,
        payload: {
          options: filterByShift(project.programs, selectedDepartment, project.shifts).map(
            value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            },
          ),
          value: null,
        },
      });
      dispatch({
        type: Types.SetProgram,
        payload: {
          options: filterByProgram(project.programs, selectedDepartment)?.map(value => {
            return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetCurriculum,
        payload: {
          options: filterByCurriculum(
            project.programs,
            selectedDepartment,
            project.curriculums,
          ).map(value => {
            return { ...value, label: `${value?.name}`, value: value?.id };
          }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetDepartment,
        payload: { options: departments, value: selectedDepartment },
      });
      // callback
      onCallback && onCallback(true);
    } else {
      dispatch({
        type: Types.SetDepartment,
        payload: { options: state?.dashboard?.departmentList, value: ALL_OPTION },
      });
      dispatch({
        type: Types.SetModality,
        payload: {
          options: filterByCampuses
            .filterByModality(project.programs, state?.dashboard?.campusValue, project.modalities)
            .map(value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetShift,
        payload: {
          options: filterByCampuses
            .filterByShift(project.programs, state?.dashboard?.campusValue, project.shifts)
            .map(value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetProgram,
        payload: {
          options: filterByCampuses
            .filterByProgram(project.programs, state?.dashboard?.campusValue)
            .map(value => {
              return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
      dispatch({
        type: Types.SetCurriculum,
        payload: {
          options: filterByCampuses
            .filterByCurriculum(
              project.programs,
              state?.dashboard?.campusValue,
              project.curriculums,
            )
            .map(value => {
              return { ...value, label: `${value?.name}`, value: value?.id };
            }),
          value: null,
        },
      });
    }
  };

  const onSearchFirstTime = () => {
    const { departmentValue, departmentList } = state?.dashboard;
    const departments = project?.departments?.map(department => ({
      label: department.code + " " + department.name,
      value: department.id,
    }));

    if (departmentList === null) {
      dispatch({ type: Types.SetDepartment, payload: { options: departments, value: null } });
      setFirstLoad(true);
    }

    if (departmentParam && departmentParam !== departmentValue?.value) {
      const findDepartment = departments?.find(value => value.value === departmentParam);
      onChange(findDepartment, departments);
    }
  };

  useEffect(() => {
    if (firstLoad) {
      onSearchFirstTime();
      setFirstLoad(false);
    }
  });

  var addAllOption = state?.dashboard?.departmentList;

  if (state?.dashboard?.departmentList) {
    addAllOption = [...state?.dashboard?.departmentList];
    addAllOption.unshift(ALL_OPTION);
  }

  return (
    <Select
      className={css.selector}
      label="Escuela"
      placeholder="Selecciona una opción"
      value={state?.dashboard?.departmentValue || ALL_OPTION}
      activeMaxHeight
      emptyOptionsText="Sin resultados"
      error={error}
      isClearable
      options={addAllOption ?? state?.dashboard?.departmentList}
      onChange={value => onChange(value, state?.dashboard?.departmentList)}
      disabled={state?.dashboard?.campusValue ? false : true}
    />
  );
};

export default DepartmentsSelector;
