import React, { useState, useEffect } from "react";
import { Button } from "@foris/avocado-ui";
import cx from "classnames";
import Modal from "../../modal/Modal";
import ScenariosSelect from "./contextSelect/ScenariosSelect";
import ProjectsSelect from "./contextSelect/ProjectsSelect";
import WorkspaceSelect from "./contextSelect/WorkspaceSelect";
import css from "./context-modal.module.scss";

interface ISelectValue {
  value: string;
  label: string;
}

interface IContext {
  scenarioId?: string;
  originId?: string;
  workspaceId?: string;
}

interface IContextModalProps {
  open: boolean;
  onClose: Function;
  workspace: ISelectValue;
  scenario: ISelectValue;
  project: ISelectValue;
  institutionId: string;
  onSave: any;
  defaultContext?: IContext;
}

const defaultValue = { label: "", value: "" };

const ContextModal: React.FC<IContextModalProps> = (props: IContextModalProps) => {
  const { institutionId, defaultContext } = props;
  const [open, setChange] = useState(props.open);
  const [workspaceValue, setWorkspace] = useState(props.workspace);
  const [scenarioValue, setScenario] = useState(props.scenario);
  const [projectValue, setProject] = useState(props.project);

  useEffect(() => {
    if (open !== props.open) setChange(props.open);
  }, [open, props.open]);

  useEffect(() => {
    setWorkspace(props.workspace);
    setScenario(props.scenario);
    setProject(props.project);
  }, [props.workspace, props.scenario, props.project]);

  const validateData = (): void => {
    const { workspace, scenario } = props;
    const newWorkspace = workspaceValue ? workspaceValue.value : null;
    if (workspace?.value !== newWorkspace) setWorkspace(workspace);
    const newScenario = scenarioValue ? scenarioValue.value : null;
    if (scenario?.value !== newScenario) setScenario(scenario);
  };

  let activeSave = false;
  let allowClose = props.workspace?.value && props.scenario?.value && props.project?.value;

  if (
    workspaceValue &&
    workspaceValue?.value != "" &&
    scenarioValue &&
    scenarioValue?.value != "" &&
    projectValue &&
    projectValue?.value != ""
  ) {
    activeSave = true;
  }

  return (
    <Modal
      title="CAMBIO DE CONTEXTO"
      show={open}
      onClose={(): void => {
        if (allowClose) {
          validateData();
          props.onClose();
        }
      }}
      headerColor="#294870"
    >
      <div className={css.context_content}>
        <div className={css.context_select}>
          <div className={css.input_select}>
            <label>AMBIENTE</label>
            <WorkspaceSelect
              workspaceValue={workspaceValue || null}
              defaultWorkspaceId={defaultContext.workspaceId || null}
              onChange={(value: any): void => {
                setWorkspace(value);
                setScenario(defaultValue);
                setProject(defaultValue);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>ESCENARIO</label>
            <ScenariosSelect
              workspaceValue={workspaceValue}
              scenarioValue={scenarioValue}
              defaultScenarioId={defaultContext.scenarioId || null}
              onChange={(value: any): void => {
                setScenario(value);
                setProject(defaultValue);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>PROYECTO</label>
            <ProjectsSelect
              institutionId={institutionId}
              scenarioValue={scenarioValue}
              workspaceValue={workspaceValue}
              defaultOriginId={defaultContext.originId || null}
              projectValue={projectValue}
              onChange={(value: any) => setProject(value)}
            />
          </div>
        </div>
        <footer>
          <Button
            className={cx(css.btn_left_footer_primary)}
            typeButton="transparent"
            onClick={(): void => {
              props.onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            className={cx(css.btn_footer_primary, !activeSave && css.disabled)}
            disabled={!activeSave}
            onClick={(): void => {
              props.onClose();
              props.onSave({
                context: {
                  workspace: workspaceValue,
                  scenario: scenarioValue,
                  project: projectValue,
                },
              });
            }}
          >
            Guardar
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

export default ContextModal;
