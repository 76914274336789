import React, { useState, useEffect, useContext } from "react";
import { Select } from "@foris/avocado-ui";
import { useQueryParam } from "../../../utils/utils";
import { ContextApp } from "../../../../../context/AppContext";
import { Types } from "../../../../../context/dashboard.reducer";
import { IForecastContextInterface } from "../../../../forecast/context";
import { filterByProgram, filterByCurriculum } from "../../../utils/filterByShift";
import css from "./selector.module.scss";

type IOption = {
  label: string;
  value: string;
};

interface IShiftSelectorProps {
  error?: string;
  onCallback?: (setShifReady: boolean) => void;
  project: any;
  context: IForecastContextInterface;
}

const ShiftSelector: React.FC<IShiftSelectorProps> = (props: IShiftSelectorProps) => {
  const { error, onCallback, project } = props;
  const { state, dispatch } = useContext(ContextApp);
  const [firstLoad, setFirstLoad] = useState(true);
  const shiftParam = useQueryParam()?.get("shift");
  const ALL_OPTION = { value: null, label: "Todas las jornadas", id: "", name: "", code: "" };

  const onChange = (shift: IOption) => {
    dispatch({
      type: Types.SetProgram,
      payload: {
        options: filterByProgram(
          project.programs,
          state?.dashboard?.departmentValue,
          state?.dashboard?.modalityValue,
          shift,
        )?.map(value => {
          return { ...value, label: `${value?.code} ${value?.name}`, value: value?.id };
        }),
        value: null,
      },
    });
    dispatch({
      type: Types.SetCurriculum,
      payload: {
        options: filterByCurriculum(
          project.programs,
          state?.dashboard?.departmentValue,
          project.curriculums,
          state?.dashboard?.modalityValue,
          shift,
        ).map(value => {
          return { ...value, label: `${value?.name}`, value: value?.id };
        }),
        value: null,
      },
    });
    dispatch({
      type: Types.SetShift,
      payload: { options: state?.dashboard?.shiftList, value: shift?.value ? shift : ALL_OPTION },
    });
    shift && onCallback && onCallback(true);
  };

  const onSearchFirstTime = () => {
    const { ShiftValue } = state?.dashboard;
    if ((shiftParam && !ShiftValue?.value) || shiftParam !== ShiftValue?.value) {
      const findShift = state?.dashboard?.shiftList?.find(value => value.value === shiftParam);
      onChange(findShift);
    }
  };

  useEffect(() => {
    if (firstLoad && shiftParam && state?.dashboard?.shiftList?.length > 0) {
      onSearchFirstTime();
      setFirstLoad(false);
    }
  });

  const addAllOption = [...state?.dashboard?.shiftList];
  addAllOption.unshift(ALL_OPTION);

  return (
    <Select
      className={css.selector}
      activeMaxHeight
      value={state?.dashboard?.ShiftValue || ALL_OPTION}
      emptyOptionsText="Sin resultados"
      error={error}
      isClearable
      label="Jornada"
      options={addAllOption}
      placeholder="Selecciona una opción"
      onChange={onChange}
      disabled={state?.dashboard?.campusValue ? false : true}
    />
  );
};

export default ShiftSelector;
