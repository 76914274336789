import React, { useState, useEffect, useContext } from "react";
import { Select } from "@foris/avocado-ui";
import { useQueryParam } from "../../../utils/utils";
import { IForecastContextInterface } from "../../../../forecast/context";
import { ContextApp } from "../../../../../context/AppContext";
import { Types } from "../../../../../context/dashboard.reducer";
import css from "./selector.module.scss";

type IOption = {
  label: string;
  value: string;
};

interface ICurriculumsSelectorProps {
  error?: string;
  onCallback?: (setCurriculumReady: boolean) => void;
  project: any;
  context: IForecastContextInterface;
}

const CurriculumsSelector: React.FC<ICurriculumsSelectorProps> = (
  props: ICurriculumsSelectorProps,
) => {
  const { state, dispatch } = useContext(ContextApp);
  const { error, onCallback } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const curriculumParam = useQueryParam()?.get("curriculum");
  const programId = state.dashboard?.programValue?.id;
  const ALL_OPTION = { value: null, label: "Todos los currículos", id: "", name: "", code: "" };

  const onChange = (curriculum: IOption) => {
    if (curriculum) {
      dispatch({
        type: Types.SetCurriculum,
        payload: { options: state?.dashboard?.curriculumList, value: curriculum },
      });
      onCallback && onCallback(true);
    } else {
      dispatch({
        type: Types.SetCurriculum,
        payload: { options: state?.dashboard?.curriculumList, value: null },
      });
    }
  };

  const onSearchFirstTime = () => {
    const { curriculumValue } = state?.dashboard;
    if (
      (curriculumParam && !curriculumValue?.value) ||
      curriculumParam !== curriculumValue?.value
    ) {
      const findCurriculum = state?.dashboard?.curriculumList?.find(
        value => value.value === curriculumParam,
      );
      onChange(findCurriculum);
    }
  };

  useEffect(() => {
    if (firstLoad && curriculumParam && state?.dashboard?.curriculumList?.length > 0) {
      onSearchFirstTime();
      setFirstLoad(false);
    }
  });

  const eligiblePrograms = programId
    ? state.dashboard?.curriculumList?.filter(curriculum => curriculum.program?.id === programId)
    : [...state?.dashboard?.curriculumList];
  eligiblePrograms.unshift(ALL_OPTION);

  return (
    <Select
      className={css.selector}
      label="Curriculum"
      placeholder="Selecciona una opción"
      value={state?.dashboard?.curriculumValue || ALL_OPTION}
      emptyOptionsText="Sin resultados"
      error={error}
      isClearable
      options={eligiblePrograms}
      onChange={onChange}
      disabled={state?.dashboard?.campusValue ? false : true}
    />
  );
};

export default CurriculumsSelector;
