import { ActionMap } from "../models/IActionMap";
import { Campus, Department, Modality, Shift, Curriculum, Program } from "../models/ISchema";

export type IOption = {
  value: string;
  label: string;
};

export interface ICampusOptions extends IOption, Campus {}
export interface IDepartmentOptions extends IOption, Department {}
export interface IModalityOptions extends IOption, Modality {}
export interface IShiftOptions extends IOption, Shift {}
export interface IProgramOptions extends IOption, Program {}
export interface ICurriculumOptions extends IOption, Curriculum {}

export type DashboardTypes = {
  campusList?: ICampusOptions[];
  campusValue?: IOption;
  departmentList?: IDepartmentOptions[];
  departmentValue?: IOption;
  modalityList?: IModalityOptions[];
  modalityValue?: IOption;
  shiftList?: IShiftOptions[];
  ShiftValue?: IOption;
  programList?: IProgramOptions[];
  programValue?: IProgramOptions;
  curriculumList?: ICurriculumOptions[];
  curriculumValue?: IOption;
};

export type SelectDashboard = {
  options:
    | ICampusOptions[]
    | IModalityOptions[]
    | IDepartmentOptions[]
    | IShiftOptions[]
    | ICurriculumOptions[]
    | IProgramOptions[];
  value: IOption;
};

export enum Types {
  SetCampus = "SET_CAMPUS",
  SetDepartment = "SET_DEPARTMENT",
  SetModality = "SET_MODALITY",
  SetShift = "SET_SHIFT",
  SetCurriculum = "SET_CURRICULUM",
  SetProgram = "SET_PROGRAM",
}

type DraftDashboardPayload = {
  [Types.SetCampus]: SelectDashboard;
  [Types.SetDepartment]: SelectDashboard;
  [Types.SetModality]: SelectDashboard;
  [Types.SetShift]: SelectDashboard;
  [Types.SetCurriculum]: SelectDashboard;
  [Types.SetProgram]: SelectDashboard;
};

export type DashboardActions = ActionMap<DraftDashboardPayload>[keyof ActionMap<
  DraftDashboardPayload
>];

export const dashboardReducer = (state: DashboardTypes, action: DashboardActions) => {
  switch (action.type) {
    case Types.SetCampus:
      return {
        ...state,
        campusList: action?.payload?.options,
        campusValue: action?.payload?.value,
      };
    case Types.SetDepartment:
      return {
        ...state,
        departmentList: action?.payload?.options,
        departmentValue: action?.payload?.value,
      };
    case Types.SetModality:
      return {
        ...state,
        modalityList: action?.payload?.options,
        modalityValue: action?.payload?.value,
      };
    case Types.SetShift:
      return {
        ...state,
        shiftList: action?.payload?.options,
        ShiftValue: action?.payload?.value,
      };
    case Types.SetCurriculum:
      return {
        ...state,
        curriculumList: action?.payload?.options,
        curriculumValue: action?.payload?.value,
      };
    case Types.SetProgram:
      return {
        ...state,
        programList: action?.payload?.options,
        programValue: action?.payload?.value,
      };
    default:
      return state;
  }
};
