import gql from "graphql-tag";

export const unitsSubscription = gql`
  subscription unitsSubscription($projectId: ID!) {
    unitUpdated(projectId: $projectId) {
      id
      kind
      label
      progress
      totalDemand
      checkedCount
      programName
      curriculumName
      tags
      nextUnit
      previusUnit
      demands {
        id
        course {
          id
          code
          name
        }
        value
        adjusted
        checked
        own
        courseCode
        courseLabel
        programName
        curriculumName
      }
    }
  }
`;
