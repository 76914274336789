import React, { useState } from "react";
import { Query, useApolloClient } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Icon } from "@foris/avocado-ui";
import { Form, Modal, Table } from "semantic-ui-react";
import styled from "styled-components";
import { SearchField } from "../index";
import { GET_DEPARTMENTS, GET_CAMPUSES, GET_UNITS } from "../../../dashboard/queries";
import { ADD_CROSSLIST_MUTATION } from "../../queries";
import Forecast from "../../../../../../../../routes/Forecast";
import cx from "classnames";
import css from "./formAdd.module.scss";

const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
};

const TableWrapper = styled.div`
  .ui.table > tbody > tr > td,
  .ui.table > tr > td {
    padding: 0.8em 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:last-child {
    padding-right: 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:first-child {
    padding-left: 0.9em;
  }
`;

const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    id: d.id,
    text: d.name,
  }));
};

const FormCLAdd = (props: any) => {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [demands, setDemands]: any = useState({});
  const [department, setDeparment] = useState({ value: null });
  const [campus, setCampus] = useState({ value: null });
  const [isLoading, setIsLoading] = useState(false);
  const [codeHasEmptyError, setCodeHasEmptyError] = useState(false);
  const [departmentHasEmptyError, setDepartmentHasEmptyError] = useState(false);
  const [campusHasEmptyError, setCampusHasEmptyError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [countDemandsWithCrossList, setCountDemandsWithCrossList] = useState(0);
  const [codeHasIncorrectValue, setCodeHasIncorrectValue] = useState(false);
  const routes: Forecast = props.routes;

  const client = useApolloClient();

  const handlecodeChange = (e: React.SyntheticEvent, { value }: any) => {
    const inputValue = value.toUpperCase();
    if (/^[A-Z\d._-]*$/.test(inputValue)) {
      setCodeHasIncorrectValue(false);
      setCodeHasEmptyError(false);
    }
    setCode(inputValue);
  };

  const handleNameChange = (e: React.SyntheticEvent, { value }: any) => setName(value);

  const onSelectDemand = (demand: any) => {
    setDemands({ ...demands, [demand.id]: demand });
  };

  const demandsFactory = (demandsList: any) => {
    const array: any[] = [];

    Object.keys(demandsList).forEach((demandKey: any) => {
      array.push({
        id: demandKey,
        value: demandsList[demandKey].value,
        checked: demandsList[demandKey].checked,
        adjusted: demandsList[demandKey].adjusted,
      });
    });

    return array;
  };

  const sendForm = () => {
    setIsLoading(true);
    const demandsF = demandsFactory(demands);

    const crossListAddObject = {
      input: {
        kind: "CROSSLIST",
        label: code,
        description: name,
        projectId: routes.context.project,
        departmentId: department.value,
        demands: demandsF,
      },
    };

    client
      .mutate({
        mutation: ADD_CROSSLIST_MUTATION,
        variables: crossListAddObject,
        update: (proxy: any, response: any) => {
          // if departmentId is null === || !currentProgramList --> 'not have cache for this query'
          if (
            props.dashboard.currentDepartmentListSelected.value ||
            props.dashboard.currentProgramListSelected.value ||
            props.dashboard.currentCurriculumListSelected.value
          ) {
            const departmentsToUpdate: any = [];

            props.dashboard.currentProgramList.forEach((item: any) => {
              departmentsToUpdate.push(item.id === "*" ? null : item.id);
            });

            // update all program in current department
            departmentsToUpdate.forEach((programId: any) => {
              try {
                const data = proxy.readQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: routes.context.project,
                    filter: {
                      departmentId: props.dashboard.currentDepartmentListSelected.value,
                      programId: programId,
                      curriculumId:
                        props.dashboard.currentCurriculumListSelected.value === "*"
                          ? null
                          : props.dashboard.currentCurriculumListSelected.value,
                    },
                  },
                });

                if (data) {
                  data.project.dashboard.crosslistView.push(response.data.addUnit);

                  proxy.writeQuery({
                    query: GET_UNITS,
                    variables: {
                      projectId: routes.context.project,
                      filter: {
                        departmentId: props.dashboard.currentDepartmentListSelected.value,
                        programId: programId,
                        curriculumId:
                          props.dashboard.currentCurriculumListSelected.value === "*"
                            ? null
                            : props.dashboard.currentCurriculumListSelected.value,
                      },
                    },
                    data: data,
                  });
                }
              } catch (e) {
                console.log(e);
              }
            });
          }
        },
      })
      .then((response: any) => {
        const status = response.data.addUnit.status;
        if (status.code) {
          switch (status.code) {
            case 200:
              toast.info("La lista se ha creado correctamente", TOAST_OPTIONS);
              window.location.href = routes.dashboard();
              break;
            case 400:
              if (status.msg === "CROSSLIST_LABEL_ALREADY_EXIST") {
                toast.warn("Ya existe una lista con este código", TOAST_OPTIONS);
              } else if (status.msg === "EMPTY_DEMAND") {
                toast.warn("No puedes crear una lista vacía", TOAST_OPTIONS);
              }
              break;
            default:
              toast.warn("Ocurrió un error, inténtalo nuevamente.", TOAST_OPTIONS);
              break;
          }
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (code === "") {
      setCodeHasEmptyError(true);
      return;
    }

    if (!/^[A-Z\d._-]*$/.test(code)) {
      setCodeHasIncorrectValue(true);
      return;
    }

    if (!department.hasOwnProperty("value")) {
      setDepartmentHasEmptyError(true);
      return;
    }

    let demandsWithCrossList = 0;
    Object.keys(demands).forEach((key: any) => {
      if (
        demands[key]["relatedUnits"] &&
        demands[key]["relatedUnits"]["crosslist"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"]
      ) {
        demandsWithCrossList++;
      }
    });

    if (demandsWithCrossList > 0) {
      setCountDemandsWithCrossList(demandsWithCrossList);
      setShowConfirmation(true);
    } else {
      sendForm();
    }
  };

  const updateTotalValue = (): number => {
    let total = 0;
    Object.entries(demands).forEach((demand: any) => (total += demand[1].adjusted));
    return total;
  };

  return (
    <>
      <Card.Simple className={cx(css.crossList, "container-row")}>
        <h3 className={cx(css.crossList_title, "col_12")}>Crear lista cruzada</h3>
        <Form className={cx(css.form, "container-row")}>
          <Form.Group className={cx(css.form_group, "container-row")}>
            <Form.Field width={3}>
              <Form.Input
                name="code"
                label="Código"
                onChange={handlecodeChange}
                placeholder="Código"
                error={codeHasEmptyError || codeHasIncorrectValue}
              />
              {codeHasIncorrectValue && (
                <p>
                  <small>
                    El código debe contener sólo caracteres alfanuméricos, puntos y guiones
                  </small>
                </p>
              )}
            </Form.Field>
            <Form.Field width={3}>
              <Form.Input
                label="Nombre"
                name="name"
                placeholder="Nombre"
                onChange={handleNameChange}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Query query={GET_CAMPUSES} variables={{ projectId: routes.context.project }}>
                {({ loading, error, data }: any) => {
                  if (loading) return <p>Loading...</p>;
                  if (error) return <p>Error :(</p>;
                  return (
                    <>
                      <Form.Select
                        search
                        label="Sede"
                        className="ellipsisText"
                        options={filterFactory(data.project.campuses)}
                        defaultValue={campus.value}
                        error={campusHasEmptyError}
                        placeholder="Selecciona una escuela"
                        onChange={(e: React.SyntheticEvent, filterValue: any) => {
                          setCampus(filterValue);
                          setCampusHasEmptyError(false);
                        }}
                      />
                    </>
                  );
                }}
              </Query>
            </Form.Field>
            <Form.Field width={4}>
              <Query query={GET_DEPARTMENTS} variables={{ projectId: routes.context.project }}>
                {({ loading, error, data }: any) => {
                  if (loading) return <p>Loading...</p>;
                  if (error) return <p>Error :(</p>;

                  if (campus.value) {
                    const departmentByCampus = data.project.departments.filter((item: any) => {
                      return item.campus.id == campus.value;
                    });

                    return (
                      <>
                        <Form.Select
                          search
                          label="Escuela"
                          className="ellipsisText"
                          options={filterFactory(departmentByCampus)}
                          defaultValue={department.value}
                          error={departmentHasEmptyError}
                          placeholder="Selecciona una escuela"
                          onChange={(e: React.SyntheticEvent, filterValue: any) => {
                            setDeparment(filterValue);
                            setDepartmentHasEmptyError(false);
                          }}
                        />
                      </>
                    );
                  } else {
                    return <></>;
                  }
                }}
              </Query>
            </Form.Field>
          </Form.Group>
        </Form>
      </Card.Simple>
      <Form className={cx(css.search, "container-row")}>
        <Form.Field width={3}>
          <label className={css.search_label}>Asignaturas</label>
          <SearchField
            projectId={routes.context.project}
            onSelectDemand={onSelectDemand}
            excludeFromResults={demands}
          />
        </Form.Field>
      </Form>
      <TableWrapper className={cx(css.tableWrapper, "container-row")}>
        <Table className={cx(css.tableWrapper_content, "col_12")} basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sede</Table.HeaderCell>
              <Table.HeaderCell>Carrera</Table.HeaderCell>
              <Table.HeaderCell>Currículo</Table.HeaderCell>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Asignatura</Table.HeaderCell>
              <Table.HeaderCell>Lista cruzada</Table.HeaderCell>
              <Table.HeaderCell>Demanda</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          {Object.keys(demands).length ? (
            <Table.Body>
              {Object.keys(demands).map((key: string) => {
                if (demands[key]) {
                  return (
                    <Table.Row key={demands[key].id}>
                      <Table.Cell>{demands[key].course.curriculum.program.campus.name}</Table.Cell>
                      <Table.Cell>{demands[key].course.curriculum.program.name}</Table.Cell>
                      <Table.Cell>{demands[key].course.curriculum.name}</Table.Cell>
                      <Table.Cell>{demands[key].course.code}</Table.Cell>
                      <Table.Cell>{demands[key].course.name}</Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {demands[key].relatedUnits && demands[key].relatedUnits.crosslist ? (
                          <Link
                            to={props.routes.unit(demands[key].relatedUnits.crosslist.id)}
                            target="_blank"
                          >
                            {demands[key].relatedUnits.crosslist.code}
                          </Link>
                        ) : (
                          <></>
                        )}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {demands[key].adjusted}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="buttons">
                          <a
                            className="button"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const newDemands = { ...demands };
                              delete newDemands[key];
                              setDemands(newDemands);
                            }}
                          >
                            <Icon icon="trash" size={14} />
                          </a>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                } else {
                  return <></>;
                }
              })}
              <Table.Row style={{ backgroundColor: "#f1efef" }}>
                <Table.Cell colSpan={5}>TOTAL</Table.Cell>
                <Table.Cell style={{ textAlign: "center" }}>{updateTotalValue()}</Table.Cell>
                <Table.Cell />
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body />
          )}
        </Table>
      </TableWrapper>

      <footer className={cx(css.cntBtn, "container-row")}>
        <Link to={props.routes.dashboard()} className={css.cntBtn_item}>
          <Button typeButton="transparent">Cancelar</Button>
        </Link>
        <Button className={css.cntBtn_item} onClick={(e: React.SyntheticEvent) => handleSubmit(e)}>
          Crear lista cruzada
        </Button>
      </footer>

      {/* MODAL */}

      <Modal size="small" open={showConfirmation}>
        {/* showConfirmation */}
        <Modal.Header>Confirmar cambio</Modal.Header>
        <Modal.Content>
          <p>
            {countDemandsWithCrossList > 1
              ? `Con esta acción se cambiarán ${countDemandsWithCrossList} asignaturas desde otras listas cruzadas a la presente.`
              : `Con esta acción se cambiará ${countDemandsWithCrossList} asignatura desde otra lista cruzada a la presente.`}
          </p>
          <p className="is-pulled-right">¿Desea continuar?</p>
        </Modal.Content>
        <Modal.Actions className={cx(css.actions, "container-row")}>
          <Button
            typeButton="transparent"
            className={css.actions_btn}
            onClick={() => {
              setShowConfirmation(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            className={css.actions_btn}
            onClick={() => {
              setShowConfirmation(false);
              sendForm();
            }}
          >
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withRouter(FormCLAdd);
